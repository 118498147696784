<template>
  <div class="login-container">
    <div class="login-window">
      <h1>Login</h1>
      <form @submit.prevent="handleSubmit">
        <input
          v-model="username"
          type="text"
          placeholder="Username"
          class="input-field"
        />
        <input
          v-model="password"
          type="password"
          placeholder="Password"
          class="input-field"
        />
        <button type="submit" class="login-button">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const params = new URLSearchParams();
        params.append('username', this.username);
        params.append('password', this.password);

        const response = await axios.post('/token', params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (response.data && response.data.access_token) {
          const decodedToken = this.decodeAccessToken(response.data.access_token);
          localStorage.setItem('access_token', response.data.access_token);
          localStorage.setItem('user', JSON.stringify({
            name: decodedToken.payload.name,
            roleid: decodedToken.payload.roleid,
            user_id: decodedToken.payload.user_id,
          }));

          // Notify app about login
          window.dispatchEvent(new Event('storage'));

          // Dispatch Vuex actions to update authentication state and fetch menu items
          await this.$store.dispatch('login', {
            token: response.data.access_token,
            user: {
              name: decodedToken.payload.name,
              roleid: decodedToken.payload.roleid,
              user_id: decodedToken.payload.user_id,
            },
          });
          await this.$store.dispatch('applicants/fetchApplicants');

          await this.$router.push('/dashboard');
          this.$router.go(0);
          
          // Show success notification
          useToast().success('Login successful!');
        } else {
          console.error('Invalid response from server');
        }
      } catch (error) {
        console.error('Login failed:', error);
        // Show error notification
        useToast().error('Login failed. Please check your credentials.');
      }
    },
    decodeAccessToken(token) {
      const parts = token.split('.');
      if (parts.length !== 3) {
        throw new Error('Invalid token format');
      }
      return {
        header: JSON.parse(atob(parts[0])),
        payload: JSON.parse(atob(parts[1])),
        signature: parts[2],
      };
    },
  },
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body, html {
  overflow-y: hidden; /* Prevent scrolling on the entire page */
}

.login-container {
  display: flex;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  height: 90vh; /* Use full viewport height */
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  overflow: hidden; /* Prevent scrolling within the login container */
  padding: 0; /* Remove padding */
  margin: 0; /* Ensure no margin */
}

.login-window {
  width: 100%;
  max-width: 400px; /* Ensure it does not exceed viewport width */
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.login-window h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #333;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #39a020;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #0056b3;
}

.login-window form {
  display: flex;
  flex-direction: column;
}

.login-window form > * {
  margin-bottom: 15px;
}

.dx-textbox, .dx-button {
  font-size: 16px;
}
</style>
