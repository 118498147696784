<template>
  <div class="main">
    <div class="transaction-list">
      <h1 class="title">View Inspection</h1>
      <div class="row">
        <div class="container">
          <!-- Form for filtering transactions -->
          <div class="form-group">
            <label for="partyname">Party *</label>
            <select id="partyname" v-model="formData.partyname">
              <option value="0" selected>Select Party</option>
              <option v-for="applicant in party" :key="applicant.applicantid" :value="applicant.short_name">
                {{ applicant.short_name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="vesselName">Vessel *</label>
            <select id="vesselName" v-model="formData.vesselName">
              <option value="0" selected>Select Vessel</option>
              <option v-for="vessel in vessel" :key="vessel.Vesselid" :value="vessel.Vesselid">
                {{ vessel.Name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="voyageName">Voyage *</label>
            <select id="voyageName" v-model="formData.voyageName">
              <option value="0" selected>Select Voyage</option>
              <option v-for="voyage in voyage" :key="voyage.Voyageid" :value="voyage.Voyageid">
                {{ voyage.Name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="locationname">Location *</label>
            <select id="locationname" v-model="formData.locationname">
              <option value="0" selected>Select Location</option>
              <option v-for="location in location" :key="location.locationid" :value="location.locationid">
                {{ location.Name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="movementname">Movement Type *</label>
            <select id="movementname" v-model="formData.movementname">
              <option value="0" selected>Select Movement</option>
              <option v-for="movement in movement" :key="movement.Movid" :value="movement.Movid">
                {{ movement.Name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="fromdate">Activity From Date </label>
            <input type="date" id="fromdate" v-model="formData.fromdate"
              :max="new Date().toLocaleDateString('en-ca')" />
          </div>
          <div class="form-group">
            <label for="todate">Activity To Date </label>
            <input type="date" id="todate" v-model="formData.todate" :max="new Date().toLocaleDateString('en-ca')" />
          </div>
          <div class="form-group">
            <label for="contnumber">Container No</label>
            <input type="text" id="contnumber" v-model="formData.contnumber"
              placeholder="Generating Report for Specific Container(s)..." />
          </div>
          <div class="form-group">
            <button type="button" class="excel-button" @click="fetchTransactions">Show Result</button>
            <button type="button" class="cancel-button1">Cancel</button>
          </div>
        </div>
      </div>
      <div v-if="isLoading">Loading transactions...</div>
      <div v-else-if="error">Error loading transactions: {{ error }}</div>

      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
        </div>

        <div class="table-container">
          <table class="transaction-table">
            <thead>
              <tr>
                <th>
                  <span>Container Number</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('contnumber')"></i>
                </th>
                <th>
                  <span>Container Size</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('contsize')"></i>
                </th>
                <th>
                  <span>Container Type</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('conttype')"></i>
                </th>
                <th>
                  <span>Type Text</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('TypeText')"></i>
                </th>
                <th>
                  <span>Construction</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('construction')"></i>
                </th>
                <th>
                  <span>ISO Code</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('iso_code')"></i>
                </th>
                <th>
                  <span>ISO Tare Weight</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('isotarewt')"></i>
                </th>
                <th>
                  <span>ISO Gross Weight</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('isogrosswt')"></i>
                </th>
                <th>
                  <span>Applicant Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('applicantname')"></i>
                </th>
                <th>
                  <span>Account Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('accountname')"></i>
                </th>
                <th>
                  <span>Vessel Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('VesselName')"></i>
                </th>
                <th>
                  <span>Colsign</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('Colsign')"></i>
                </th>
                <th>
                  <span>Voyage Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('VoyageName')"></i>
                </th>
                <th>
                  <span>Movement Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('MovementName')"></i>
                </th>
                <th>
                  <span>Inspection Place</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('LocationName')"></i>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(transaction) in paginatedTransactions" :key="transaction.reportid">
                <td>{{ transaction.contnumber }}</td>
                <td>{{ transaction.contsize }}</td>
                <td>{{ transaction.conttype }}</td>
                <td>{{ transaction.TypeText }}</td>
                <td>{{ transaction.construction }}</td>
                <td>{{ transaction.iso_code }}</td>
                <td>{{ transaction.isotarewt }}</td>
                <td>{{ transaction.isogrosswt }}</td>
                <td>{{ transaction.applicantname }}</td>
                <td>{{ transaction.accountname }}</td>
                <td>{{ transaction.VesselName }}</td>
                <td>{{ transaction.Colsign }}</td>
                <td>{{ transaction.VoyageName }}</td>
                <td>{{ transaction.MovementName }}</td>
                <td>{{ transaction.LocationName }}</td>
                <td>
                  <button @click="openEditModal(transaction)" class="edit-button">
                    <i class="fas fa-edit"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(page)" v-for="page in totalPages" :key="page"
              :class="{ 'active': currentPage === page }" class="page-number">{{ page }}</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Inline Edit Transaction Overlay -->
    <!-- Inline Edit Transaction Overlay -->
    <div v-if="isModalVisible" class="edit-overlay">
      <div class="edit-overlay-content">
        <span class="close-btn" @click="closeModal">&times;</span>
        <h2>Container Inspection Report</h2>
        <form @submit.prevent="saveTransaction(newTransaction)">

          <!-- Row 1 -->
          <div class="form-row">
            <div class="form-group">
              <label for="contnumber">Container No:</label>
              <input type="text" id="contnumber" v-model="newTransaction.contnumber" required />
            </div>
            <div class="form-group">
              <label for="yearOfManufacture">Year of Mfg:</label>
              <input type="number" id="yearOfManufacture" v-model="newTransaction.yearOfManufacture" required />
            </div>
            <div class="form-group">
              <label for="tareWeight">Tare Weight:</label>
              <input type="number" id="tareWeight" v-model="newTransaction.isotarewt" required />
            </div>
            <div class="form-group">
              <label for="maxGrossWeight">CSC Max Gross Wt:</label>
              <input type="number" id="maxGrossWeight" v-model="newTransaction.isogrosswt" required />
            </div>
          </div>

          <!-- Row 2 -->
          <div class="form-row">
            <div class="form-group">
              <label for="cargoWeight">Cargo Wt:</label>
              <input type="number" id="cargoWeight" v-model="newTransaction.cargowt" required />
            </div>
            <div class="form-group">
              <label for="vesselName">Vessel Name:</label>
              <v-select :clearable="false" v-model="newTransaction.VesselName" :options="vessel" label="Name"
                :reduce="vessel => vessel.Vesselid" placeholder="Select Vessel Name" />
            </div>
            <div class="form-group">
              <label for="voyageNo">Voyage Name:</label>
              <v-select :clearable="false" v-model="newTransaction.VoyageName" :options="voyage" label="Name"
                :reduce="voyage => voyage.Voyageid" placeholder="Select Voyage Name" />
            </div>
            <div class="form-group">
              <label for="clearingAgent">Clearing Agent:</label>
              <v-select :clearable="false" :options="['Agent A', 'Agent B']" v-model="newTransaction.clearingAgent"
                placeholder="Select Clearing Agent" />
            </div>
          </div>

          <!-- Row 3 -->
          <div class="form-row">
            <div class="form-group">
              <label for="applicantName">Applicant Name:</label>
              <v-select :clearable="false" id="applicantName" v-model="newTransaction.applicantname"
                :options="applicant" label="applicantname" :reduce="applicant => applicant.applicantid"
                placeholder="Select Applicant Name" />
            </div>
            <div class="form-group">
              <label for="account">A/C:</label>
              <v-select :clearable="false" v-model="newTransaction.accountname" :options="account" label="accountname"
                :reduce="account => account.acccountid" placeholder="Select Account Name" />
            </div>
            <div class="form-group">
              <label for="reportType">Report Type:</label>
              <v-select :clearable="false" :options="['Survey', 'Comparator', 'Non-Eor']"
                v-model="newTransaction.reportType" placeholder="Select Report Type" />
            </div>
            <div class="form-group">
              <label for="sealNo">Seal No:</label>
              <input type="text" id="sealNo" v-model="newTransaction.sealNo" required />
            </div>
          </div>

          <!-- Row 4 -->
          <div class="form-row">
            <div class="form-group">
              <label for="inspectionPlace">Inspection Place:</label>
              <v-select :clearable="false" v-model="newTransaction.inspectionplace" :options="location" label="Name"
                :reduce="location => location.locationid" placeholder="Select a Location Name" />
            </div>
            <div class="form-group">
              <label for="inspectionDate">Inspection Date:</label>
              <input type="date" id="inspectionDate" v-model="newTransaction.inspdate" required />
            </div>
            <div class="form-group">
              <label for="time">Time:</label>
              <input type="text" id="time" v-model="newTransaction.insptime" required />
            </div>
            <div class="form-group">
              <label for="toLocation">To Location:</label>
              <v-select :clearable="false" :options="['Location A', 'Location B']" v-model="newTransaction.toLocation"
                placeholder="Select To Location" />
            </div>
          </div>

          <!-- Row 5 -->
          <div class="form-row">
            <div class="form-group">
              <label for="loadPort">Load Port:</label>
              <v-select :clearable="false" v-model="newTransaction.loadPort" :options="port" label="name"
                :reduce="port => port.portid" placeholder="Select Port Name" />
            </div>
            <div class="form-group">
              <label for="transshipmentPort">Transshipment Port:</label>
              <v-select :clearable="false" v-model="newTransaction.transshipmentPort" :options="port" label="name"
                :reduce="port => port.portid" placeholder="Select Port Name" />
            </div>
            <div class="form-group">
              <label for="finalPort">Final Port:</label>
              <v-select :clearable="false" v-model="newTransaction.finalPort" :options="port" label="name"
                :reduce="port => port.portid" placeholder="Select Port Name" />
            </div>
            <div class="form-group">
              <label for="transporterCode">Transporter Code:</label>
              <v-select :clearable="false" :options="['Code A', 'Code B']" v-model="newTransaction.transporterCode"
                placeholder="Select Tranport Code" />
            </div>
          </div>

          <!-- Row 6 -->
          <div class="form-row">
            <div class="form-group">
              <label for="transportMode">Transport Mode:</label>
              <v-select :clearable="false" :options="['Road', 'Train']" v-model="newTransaction.transportMode"
                placeholder="Select Tranport Mode" />
            </div>
            <div class="form-group">
              <label for="bookingRefNo">Booking Ref. No:</label>
              <input type="text" id="bookingRefNo" v-model="newTransaction.bookingRefNo" required />
            </div>
            <div class="form-group">
              <label for="jobOrderNo">Job Order No:</label>
              <input type="text" id="jobOrderNo" v-model="newTransaction.jobOrderNo" />
            </div>
            <div class="form-group">
              <label for="hazStatus">Haz Status:</label>
              <v-select :clearable="false" :options="['Yes', 'No']" v-model="newTransaction.hazstat" />
            </div>
          </div>

          <!-- Row 7 -->
          <div class="form-row">
            <div class="form-group">
              <label for="note">Note:</label>
              <textarea id="note" v-model="newTransaction.note"></textarea>
            </div>
          </div>

          <!-- Radio Buttons for Sound/Damage -->
          <div class="form-row">
            <div class="form-group">
              <div class="radio-group">
                <label class="condition">Condition:</label>
                <span>
                  <input type="radio" id="sound" value="Sound" v-model="newTransaction.condition" />
                  <label for="sound">Sound</label>
                </span>
                <span>
                  <input type="radio" id="damage" value="Damage" v-model="newTransaction.condition" />
                  <label for="damage">Damage</label>
                </span>
              </div>
            </div>


          </div>

          <!-- Save and Cancel buttons -->
          <div class="form-buttons">
            <button type="submit" class="save-button">Save</button>
            <button type="button" class="cancel-button" @click="closeModal">Cancel</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</template>



<script>
import axios from 'axios';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      transactions: [],
      party: [],
      vessel: [],
      applicant: JSON.parse(localStorage.getItem('applicants')) || [],
      voyage: [],
      port:JSON.parse(localStorage.getItem('ports')) || [],
      location: JSON.parse(localStorage.getItem('location')) || [],
      movement: [],
      account: JSON.parse(localStorage.getItem('accounts')) || [],
      nameError: '',
      isLoading: false,
      error: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      selectedTransaction: null,
      isModalVisible: false, // Control visibility of the modal
      formData: {
        applicantid: '0',
        accountid: '0',
        partyname: '0',
        vesselName: '0',
        voyageName: '0',
        locationname: '0',
        movementname: '0',
        fromdate: '',
        todate: '',
        contnumber: '',
      },
      newTransaction: {
        contnumber: '',
        contsize: '',
        conttype: '',
        TypeText: '',
        construction: '',
        iso_code: '',
        isotarewt: '',
        isogrosswt: '',
        applicantname: '',
        accountname: '',
        VesselName: '',
        Colsign: '',
        VoyageName: '',
        MovementName: '',
        LocationName: '',
        yearOfManufacture: '',
        tareWeight: '',
        maxGrossWeight: '',
        voyageNo: '',
        cargoWeight: '',
        movementName: '',
        clearingAgent: '',
        reportType: '',
        inspectionDate: '',
        inspectionPlace: '',
        time: '',
        toLocation: '',
        sealNo: '',
        slotNo: '',
        shift: '',
        surveyorName: '',
        loadPort: '',
        transshipmentPort: '',
        finalPort: '',
        hazStatus: '',
        transporterCode: '',
        transportMode: '',
        bookingRefNo: '',
        jobOrderNo: '',
        note: '',
        condition: '',
      },
      sortDirection: {
        contnumber: 'asc',
        contsize: 'asc',
        conttype: 'asc',
        TypeText: 'asc',
        construction: 'asc',
        iso_code: 'asc',
        isotarewt: 'asc',
        isogrosswt: 'asc',
        applicantname: 'asc',
        accountname: 'asc',
        VesselName: 'asc',
        Colsign: 'asc',
        VoyageName: 'asc',
        MovementName: 'asc',
        LocationName: 'asc',
      },
    };
  },
  created() {
    this.checkPermissions();
    this.fetchDropdownData();
  },
  computed: {
    filteredTransactions() {
      return this.transactions.filter(transaction => {
        return Object.values(transaction).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedTransactions() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredTransactions.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredTransactions.length / this.rowsPerPage);
    },
  },
  methods: {
    async fetchDropdownData() {
      try {
        const token = localStorage.getItem('access_token');
        const config = { headers: { 
          'accept': 'application/json',
          'Authorization': `Bearer ${token}` } };

        const partyRes = await axios.get('/linkParties', config);
        this.party = partyRes.data[2];

        const vessRes = await axios.get('/vessel_list', config);
        this.vessel = vessRes.data[2];

        const voyRes = await axios.get('/voyage_list', config);
        this.voyage = voyRes.data[2];

        const locRes = await axios.get('/location_list', config);
        this.location = locRes.data[2];

        const moveRes = await axios.get('/movement_list', config);
        this.movement = moveRes.data[2];
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    },
    checkPermissions() {
      const user = JSON.parse(localStorage.getItem('user'));
      this.hasPermissions = user && user.role === 'admin';
    },
    
    openEditModal(transaction) {
      this.selectedTransaction = transaction;
      console.log(transaction);
      this.newTransaction = { ...transaction };
      console.log('hello',this.newTransaction);
      this.isModalVisible = true; // Show the modal
    },
    closeModal() {
      this.isModalVisible = false; // Hide the modal
      this.selectedTransaction = null; // Reset selected transaction
    },
    async saveTransaction(transaction) {
      if (this.nameError) return;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }

      try {
        const response = await axios.put(`/update_container/${transaction.contnumber}`, transaction, {
          headers: { 
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const index = this.transactions.findIndex(t => t.reportid === response.data[2].reportid);
        if (index !== -1) {
          this.transactions.splice(index, 1, response.data[2]);
        } else {
          this.transactions.push(response.data[2]);
        }
        this.closeModal(); // Close the modal after saving
      } catch (err) {
        this.error = 'Error saving transaction';
        console.error(err);
      }
    },
    async fetchTransactions() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const response = await axios.get('/transactions', {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}` },
          params: {
            applicant: this.formData.applicantid,
            account: this.formData.accountid,
            vessel: this.formData.vesselName,
            voyage: this.formData.voyageName,
            movement: this.formData.movementname,
            location: this.formData.locationname,
            fromdate: this.formData.fromdate,
            todate: this.formData.todate,
            contnumber: this.formData.contnumber,
          },
        });
        this.transactions = response.data[2];
      } catch (err) {
        this.error = 'Error fetching transactions';
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    setRowsPerPage(option) {
      this.rowsPerPage = option;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
  },
};
</script>



<style scoped>
.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    
}

.container {
    width: 100%;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 15px;
    width: 22%;
    padding: 5px;
    float: left;
}

label {
    display: block;
    margin-bottom: 5px;
}

select,
input[type="text"],
input[type="date"] {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

input::placeholder {
    color: #888;
}

.excel-button,
.generate-button,
.cancel-button1 {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.excel-button {
    background-color: #17a2b8;
}

.cancel-button1 {
    background-color: #dc3545;
}

button:hover {
    opacity: 0.9;
}

.date-range {
    display: flex;
    justify-content: space-between;
}

.date-range label {
    flex: 1;
    margin: 0 10px;
}

.date-range input {
    flex: 2;
}

.main {
    background-color: #f0f2f5;
    font-family: 'Roboto', sans-serif;
}



.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    visibility: visible;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    /* Position above the button */
    left: 50%;
    margin-left: -60px;
    /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
    opacity: 1;
}

.transaction-list {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
    color: #333;
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.search-container {
    position: relative;
}

.search-container input {
    padding: 8px 30px 8px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.error-message {
    color: #d9534f;
    /* Bootstrap's danger color */
    font-size: 12px;
    margin-bottom: 4px;
    /* Space between the error message and input field */
    padding: 4px;
    border: 1px solid #d9534f;
    border-radius: 4px;
    background-color: #f9d6d5;
    /* Light red background */
    display: inline-block;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #666;
    cursor: pointer;
}

.actions {
    display: flex;
    align-items: center;
}

.print-button {
    background-color: rgb(50, 89, 187);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.print-button:hover {
    background-color: rgb(61, 146, 231);
}

.export-button {
    background-color: rgb(50, 89, 187);
    color: white;
    border: none;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;
    margin-left: 10px;
}

.export-button:hover {
    background-color: rgb(61, 146, 231);
}

.export-options {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: left;
    top: 40px;
    border-radius: 10px;
}

.export-button:hover .export-options {
    display: block;
}

.rows-per-table {
    display: flex;
    align-items: center;
}

.rows-per-table button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: #f2f2f2;
    color: #333333;
    font-size: 14px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.rows-per-table button.active {
    background-color: rgb(50, 89, 187);
    color: white;
}

.export-options li {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    border-radius: 10px;
}

.export-options li:hover {
    background-color: #ddd;
}

.table-container {
    max-height: 100%;
    overflow: auto;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.transaction-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.transaction-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.transaction-table th .filter-icon {
    cursor: pointer;
    margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 18px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
    color: green;
}

.cancel-button {
    color: red;
}

.new-row input {
    width: 100%;
    padding: 5px;
    margin: 5px 0;
    box-sizing: border-box;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
}

.bottom-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.rows-per-table {
    display: flex;
    align-items: center;
}

.rows-per-table button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: #f2f2f2;
    color: #333333;
    font-size: 14px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.rows-per-table button.active {
    background-color: rgb(50, 89, 187);
    color: white;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 14px;
}

.pagination button {
    width: 35px;
    height: 35px;
    border: none;
    background-color: #f2f2f2;
    color: #333;
    font-size: 15px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
}

.pagination button.active {
    background-color: rgb(50, 89, 187);
    color: white;
}
/* Overlay container */
.edit-overlay {
  position: fixed; /* Ensures the overlay stays above all other content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto; /* Enables scrolling if the content exceeds viewport height */
  z-index: 9999; /* Ensures it appears above all other content */
}

/* Overlay content */
.edit-overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%; /* Adjusted width for better fit */
  max-width: 1000px; /* Adjusted max-width */
  max-height: 90vh; /* Limits the height to prevent overflow */
  overflow-y: auto; /* Enables vertical scrolling within the content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-top: 50px; /* Adds margin from the top */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

h2 {
  margin-top: 0;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.form-group {
  flex: 1 1 calc(25% - 16px); /* Ensures the form items fit in a row */
  min-width: 200px;
  margin-bottom: 16px;
  box-sizing: border-box; /* Include padding and border in total width */
}

/* Ensure label and input occupy full width of .form-group */
.form-group label,
.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  min-height: 100px;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.save-button {
  background-color: #28a745;
}

.cancel-button {
  background-color: #dc3545;
}

.save-button:hover {
  background-color: #218838;
}

.cancel-button:hover {
  background-color: #c82333;
}
.radio-group {
  display: flex; /* Use flexbox for horizontal alignment */
  align-items: center; /* Vertically center radio buttons and labels */
  justify-content: flex-start; /* Align radio buttons to the left side */
  width: 100%; /* Take up full width of the parent container */
}

.radio-group span {
  margin-right: 10px; /* Add space between radio buttons */
}

.radio-group label {
  margin-right: 5px; /* Reduce space between label and radio button */
}
.condition{
  margin-left: 75%;
}
</style>