<template>
  <div class="main">
    <div class="transaction-list">
      <h1 class="title">Bills</h1>
      <div class="controls">
        <div class="search-container">
          <input v-model="searchQuery" placeholder="Search..." />
          <i class="fas fa-search search-icon"></i>
        </div>
        <div class="actions">
          <button class="print-button" title="Print" @click="generateReport">
            <i class="fas fa-print"></i>Print
          </button>
          <button class="export-button" title="Export to CSV" @click="exportToCSV">
            <i class="fas fa-ellipsis-v"></i>
            <ul class="export-options">
              <li>Export to CSV</li>
            </ul>
          </button>
        </div>
      </div>

      <div v-if="isLoading">Loading transactions...</div>
      <div v-else-if="error">Error loading transactions: {{ error }}</div>

      <div v-else>
        <div class="table-container">
          <table class="transaction-table">
            <thead>
              <tr>
                <th @click="sortByColumn('billno')">Bill No</th>
                <th @click="sortByColumn('billdate')">Bill Date</th>
                <th @click="sortByColumn('applicantname')">Applicant Name</th>
                <th @click="sortByColumn('accountname')">Account Name</th>
                <th @click="sortByColumn('companyid')">Company Name</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(bill) in paginatedBills" :key="bill.autoid">
                <td>{{ bill.billno }}</td>
                <td>{{ formatDate(bill.billdate) }}</td>
                <td>{{ bill.applicantname }}</td>
                <td>{{ bill.accountname }}</td>
                <td>{{ companyMap[bill.companyid] || 'Unknown' }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option"
              :class="{ 'active': rowsPerPage === option }" @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(page)" v-for="page in totalPages" :key="page"
              :class="{ 'active': currentPage === page }" class="page-number">{{ page }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

import 'vue-select/dist/vue-select.css';

export default {
  
  data() {
    return {
      bills: [],
      companyMap: {},  // To map company IDs to names
      isLoading: false,
      error: null,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      sortDirection: {
        billno: 'asc',
        billdate: 'asc',
        applicantname: 'asc',
        accountname: 'asc',
        companyid: 'asc',
      },
    };
  },
  computed: {
    filteredBills() {
      if (!this.bills) {
        return [];
      }
      return this.bills.filter(bill => {
        return Object.values(bill).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    paginatedBills() {
      if (!this.bills) {
        return [];
      }
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredBills.slice(start, end);
    },
    totalPages() {
      if (!this.bills) {
        return 0;
      }
      return Math.ceil(this.filteredBills.length / this.rowsPerPage);
    },
  },
  methods: {
    async getBills() {
      this.isLoading = true;
      const token = localStorage.getItem('access_token');
      const config = { headers: { 
        'accept': 'application/json',
        'Authorization': `Bearer ${token}` } };
      try {
        const response = await axios.get('/all_bills', config);
        this.bills = response.data[2];
        await this.loadCompanyList();  // Load company names after fetching bills
      } catch (error) {
        console.error('Error fetching bills:', error);
        this.error = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    async loadCompanyList() {
      const token = localStorage.getItem('access_token');
      const config = { headers: { 
        'accept': 'application/json',
        'Authorization': `Bearer ${token}` } };
      try {
        const response = await axios.get('/company_list', config);
        const companies = response.data[2];
        this.companyMap = companies.reduce((map, company) => {
          map[company.companyid] = company.companyname;
          return map;
        }, {});
      } catch (error) {
        console.error('Error fetching company list:', error);
        this.error = error.message;
      }
    },
    exportToCSV() {
      const headers = [
        "Bill No", "Bill Date", "Applicant Name", "Account Name", "Company Name"
      ];

      const csvContent = [
        headers.join(","),
        ...this.filteredBills.map(bill =>
          headers.map(header => JSON.stringify(bill[header.toLowerCase()] || '')).join(",")
        )
      ].join("\n");

      const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "bills.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    setRowsPerPage(option) {
      this.rowsPerPage = option;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
  },
  mounted() {
    this.getBills(); // Fetch bills on component mount
  }
};
</script>
<style scoped>
.main {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.transaction-list {
  margin-bottom: 20px;
}

.title {
  margin-top: 0;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
}

.actions {
  display: flex;
  align-items: center;
}

.print-button{
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}
.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.print-button:hover,
.export-button:hover {
  background-color: rgb(30, 60, 150);
}

.export-options {
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.export-button:hover .export-options {
  display: block;
}

.export-options li {
  padding: 8px 12px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #f1f1f1;
}

.table-container {
  overflow-x: auto;
}

.transaction-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.transaction-table th,
.transaction-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.transaction-table th {
  cursor: pointer;
  background-color: #f4f4f4;
}

.transaction-table tr:hover {
  background-color: #f9f9f9;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rows-per-table {
  display: flex;
}

.rows-per-table button {
  margin-right: 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
  background-color: #fff;
}

.rows-per-table button.active {
  background-color: #007bff;
  color: white;
}

.pagination {
  display: flex;
}

.page-number {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  cursor: pointer;
}

.page-number.active {
  background-color: #007bff;
  color: white;
}
</style>
