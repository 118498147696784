<template>
  <div class="main">
    <div class="vcn-list">
      <h1 class="title">Vcn List</h1>

      <div v-if="isLoading">Loading Vcn list...</div>
      <div v-else-if="error">Error loading Vcn list: {{ error }}</div>

      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
              <button class="add-button" @click="openAddOverlay" title="Add New Container">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="table-container">
          <table class="vcn-table">
            <thead>
              <tr>
                <th>
                  <span>Vcn Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('vcnName')"></i>
                </th>
                <th>
                  <span>Vessel Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('VesselName')"></i>
                </th>
                <th>
                  <span>Import Voyage Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('import_voyageName')"></i>
                </th>
                <th>
                  <span>Export Voyage Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('export_voyageName')"></i>
                </th>
                <th>
                  <span>Area</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('area')"></i>
                </th>
                <th>
                  <span>Imp Rot No</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('ImpRotNo')"></i>
                </th>
                <th>
                  <span>Imp Rot Date</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('ImpRotDate')"></i>
                </th>
                <th>
                  <span>Exp Rot No</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('ExpRotNo')"></i>
                </th>
                <th>
                  <span>Exp Rot Date</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('ExpRotDate')"></i>
                </th>
                <th>
                  <span>Berth Date</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('BerthDate')"></i>
                </th>
                <th>
                  <span>Egm Date</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('EgmDate')"></i>
                </th>
                <th>
                  <span>Is Sail</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('IsSail')"></i>
                </th>
                <th>
                  <span>Sail Date</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('SailDate')"></i>
                </th>
                <th>
                  <span>Berth Location</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('vesselLocation')"></i>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isAddingNew" class="new-row">
                <td>
                  <div class="input-container">
                    <div v-if="nameError" class="error-message">{{ nameError }}</div>
                    <input v-model="newVcn.vcnName" placeholder="VCN Name" @input="validateVcnName" />
                  </div>
                </td>

                <td>
                  <v-select :clearable="false" v-model="newVcn.vesselID" :options="vesselNames" label="Name"
                    :reduce="vessel => vessel.Vesselid" placeholder="Select Vessel Name" />
                </td>
                <td>
                  <v-select :clearable="false" v-model="newVcn.import_voyage" :options="importVoyages" label="Name"
                    :reduce="voyage => voyage.Voyageid" placeholder="Select Import Voyage" />
                </td>
                <td>
                  <v-select :clearable="false" v-model="newVcn.export_voyage" :options="importVoyages" label="Name"
                    :reduce="voyage => voyage.Voyageid" placeholder="Select Export Voyage" />
                </td>
                <td>
                  <v-select :clearable="false" v-model="newVcn.MlocID" :options="Area" label="Name"
                    :reduce="Area => Area.locationid" placeholder="Select Area" />
                </td>
                <td><input v-model="newVcn.ImpRotNo" placeholder="Imp Rot No" /></td>
                <td><input type="date" v-model="formattedImpRotDate" placeholder="Imp Rot Date" :min="minDate"
                    @change="updateImpRotDate" /></td>
                <td><input v-model="newVcn.ExpRotNo" placeholder="Exp Rot No" /></td>
                <td><input type="date" v-model="newVcn.ExpRotDate" placeholder="Exp Rot Date"
                    :min="currentYear + '-01-01'" /></td>
                <td><input type="date" v-model="newVcn.BerthDate" placeholder="Berth Date"
                    :min="currentYear + '-01-01'" /></td>
                <td><input type="date" v-model="newVcn.EgmDate" placeholder="Egm Date" :min="currentYear + '-01-01'" />
                </td>
                <td>
                  <input type="checkbox" v-model="newVcn.IsSail" />
                </td>
                <td><input type="date" v-model="newVcn.SailDate" placeholder="Sail Date" :min="currentYear + '-01-01'"
                    :disabled="!newVcn.IsSail" /></td>
                <td>
                  <v-select :clearable="false" v-model="newVcn.vesselLocation" :options="masterLocations" label="Name"
                    :reduce="masterLocations => masterLocations.locationid" placeholder="Select Berth Location" />
                </td>
                <td v-if="hasPermissions">
                  <button @click="saveVcn" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>
              <tr v-for="(vcn) in paginatedVcns" :key="vcn.vcnID" :class="{ 'editing': vcn === selectedVcn }">
                <td v-if="vcn === selectedVcn">
                  <div v-if="nameError" class="error-message">{{ nameError }}</div>
                  <input v-model="newVcn.vcnName" placeholder="VCN Name" />
                </td>
                <td v-else>{{ vcn.vcnName }}</td>
                <td v-if="vcn === selectedVcn">
                  <v-select :clearable="false" v-model="selectedVcn.VesselName" :options="vesselNames" label="Name"
                    :reduce="vessel => vessel.Name" placeholder="Select Vessel Name" />
                </td>
                <td v-else>{{ vcn.VesselName }}</td>
                <td v-if="vcn === selectedVcn">
                  <v-select :clearable="false" v-model="newVcn.import_voyageName" :options="importVoyages" label="Name"
                    :reduce="voyage => voyage.Voyageid" placeholder="Select Import Voyage" />
                </td>
                <td v-else>{{ vcn.import_voyageName }}</td>
                <td v-if="vcn === selectedVcn">
                  <v-select :clearable="false" v-model="newVcn.export_voyageName" :options="importVoyages" label="Name"
                    :reduce="voyage => voyage.Voyageid" placeholder="Select Export Voyage" />
                </td>
                <td v-else>{{ vcn.export_voyageName }}</td>

                <td v-if="vcn === selectedVcn">
                  <v-select :clearable="false" v-model="newVcn.area" :options="Area" label="Name"
                    :reduce="Area => Area.Name" placeholder="Select Area" />
                </td>
                <td v-else>{{ vcn.area }}</td>
                <td v-if="vcn === selectedVcn">
                  <input v-model="newVcn.ImpRotNo" placeholder="Imp Rot No" />
                </td>
                <td v-else>{{ vcn.ImpRotNo }}</td>
                <td v-if="vcn === selectedVcn">
                  <input type="date" v-model="newVcn.ImpRotDate" placeholder="Imp Rot Date"
                    :min="currentYear + '-01-01'" />
                </td>
                <td v-else>{{ vcn.ImpRotDate }}</td>
                <td v-if="vcn === selectedVcn">
                  <input v-model="newVcn.ExpRotNo" placeholder="Exp Rot No" />
                </td>
                <td v-else>{{ vcn.ExpRotNo }}</td>
                <td v-if="vcn === selectedVcn">
                  <input type="date" v-model="newVcn.ExpRotDate" placeholder="Exp Rot Date"
                    :min="currentYear + '-01-01'" />
                </td>
                <td v-else>{{ vcn.ExpRotDate }}</td>
                <td v-if="vcn === selectedVcn">
                  <input type="date" v-model="newVcn.BerthDate" placeholder="Berth Date"
                    :min="currentYear + '-01-01'" />
                </td>
                <td v-else>{{ vcn.BerthDate }}</td>
                <td v-if="vcn === selectedVcn">
                  <input type="date" v-model="newVcn.EgmDate" placeholder="Egm Date" :min="currentYear + '-01-01'" />
                </td>
                <td v-else>{{ vcn.EgmDate }}</td>
                <td v-if="vcn === selectedVcn">
                  <input type="checkbox" v-model="newVcn.IsSail" />
                </td>
                <td v-else>
                  {{ vcn.IsSail }}
                </td>
                <td v-if="vcn === selectedVcn">
                  <input type="date" v-model="newVcn.SailDate" placeholder="Sail Date" :disabled="!newVcn.IsSail"
                    :min="currentYear + '-01-01'" />
                </td>
                <td v-else>
                  {{ vcn.SailDate }}
                </td>
                <td v-if="vcn === selectedVcn">
                  <v-select :clearable="false" v-model="newVcn.vesselLocation" :options="masterLocations" label="Name"
                    :reduce="masterLocations => masterLocations.locationid" placeholder="Select Berth Location" />
                </td>
                <td v-else>
                  {{ getLocationNameById(vcn.vesselLocation) }}
                </td>
                <td v-if="hasPermissions">
                  <button v-if="vcn === selectedVcn" @click="saveVcn(vcn)" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="vcn === selectedVcn" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editVcn(vcn)" class="edit-button"><i class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button @mouseenter="showTooltip" @mouseleave="hideTooltip" v-for="option in [10, 20, 30]" :key="option"
              :class="{ 'active': rowsPerPage === option }" @click="rowsPerPage = option" title="Rows Per Table">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';
import jsPDF from 'jspdf';
export default {
  components: {
    VSelect,
  },
  data() {
    return {
      vesselNames: JSON.parse(localStorage.getItem('vessel')),
      masterLocations: JSON.parse(localStorage.getItem('locations')),
      locationMap: {},  // Add location map to store ID-to-name mapping
      Area: [],
      vcns: [],
      nameError: '',
      importVoyages: JSON.parse(localStorage.getItem('voyage')),
      isLoading: false,
      error: null,
      showExportOptions:false,
      tooltipVisible: false,
      tooltipTimeout: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPageOptions: [10, 20, 30],
      rowsPerPage: 10,
      currentPage: 1,
      isAddingNew: false,
      isEditing: false,
      selectedVcn: null,
      newVcn: {
        vcnNo: '',
        vcnName: '',
        vesselID: null,
        VesselName: '',
        import_voyage: null,
        import_voyageName: '',
        export_voyage: null,
        export_voyageName: '',
        MlocID: null,
        area: '',
        ImpRotNo: '',
        ImpRotDate: null,
        ExpRotNo: '',
        ExpRotDate: null,
        BerthDate: null,
        EgmDate: null,
        IsSail: false,
        SailDate: null,
        vesselLocation: null,
        Vesselarea: null,
      },
      filters: {
        vcnID: '',
        vcnName: '',
        vesselID: '',
        VesselName: '',
        import_voyage: '',
        import_voyageName: '',
        export_voyage: '',
        export_voyageName: '',
        MlocID: '',
        area: '',
        ImpRotNo: '',
        ImpRotDate: null,
        ExpRotNo: '',
        ExpRotDate: null,
        BerthDate: null,
        EgmDate: null,
        IsSail: '',
        SailDate: null,
        vesselLocation: '',
        Vesselarea: '',
      },
      sortDirection: {
        vcnID: 'asc',
        vcnName: 'asc',
        vesselID: 'asc',
        VesselName: 'asc',
        import_voyage: 'asc',
        import_voyageName: 'asc',
        export_voyage: 'asc',
        export_voyageName: 'asc',
        MlocID: 'asc',
        area: 'asc',
        ImpRotNo: 'asc',
        ImpRotDate: 'asc',
        ExpRotNo: 'asc',
        ExpRotDate: 'asc',
        BerthDate: 'asc',
        EgmDate: 'asc',
        IsSail: 'asc',
        SailDate: 'asc',
        vesselLocation: 'asc',
        Vesselarea: 'asc',
      },
    };
  },
  watch: {
    'newVcn.vcnName'(newName) {
      if (this.isAddingNew || (this.isEditing && this.newVcn.vcnName !== this.selectedVcn.vcnName)) {
        this.checkVcnName(newName);
      }
    },
    searchQuery() {
    // Reset to the first page when search query changes
    this.currentPage = 1;
  },
  },
  created() {
    this.fetchVcns();
    this.checkPermissions();
    this.fetchMasterLocations();
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    formattedImpRotDate: {
      get() {
        // Convert from YYYY-MM-DD to DD-MM-YYYY
        return this.newVcn.ImpRotDate ? this.formatDateToDDMMYYYY(this.newVcn.ImpRotDate) : '';
      },
      set(value) {
        // Convert from DD-MM-YYYY to YYYY-MM-DD
        this.newVcn.ImpRotDate = this.formatDateToYYYYMMDD(value);
      },
    },
    minDate() {
      return `${this.currentYear}-01-01`;
    },
    filteredVcns() {
      return this.vcns
        .filter((vcn) => {
          return Object.keys(this.filters).every((key) => {
            return !this.filters[key] || String(vcn[key]).toLowerCase().includes(this.filters[key].toLowerCase());
          }) && Object.values(vcn).some((value) =>
            String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        })
        .sort((a, b) => {
          for (const key in this.sortDirection) {
            if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
          }
          return 0;
        });
    },
    paginatedVcns() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredVcns.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredVcns.length / this.rowsPerPage);
    },
    hasPrevPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
    paginatedVessels() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredVessels.slice(start, end);
    },
    paginatedPages() {
    const totalPages = this.totalPages;
    const currentPage = this.currentPage; // assuming you have a currentPage data property
    const maxPagesToShow = 10;
    let pages = [];

    if (totalPages <= maxPagesToShow) {
      // Show all pages if they are fewer than the maxPagesToShow
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Always show the first page
      pages.push(1);

      // Show a range around the current page
      let start = Math.max(2, currentPage - 4);
      let end = Math.min(totalPages - 1, currentPage + 4);

      if (start > 2) {
        pages.push('...');
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (end < totalPages - 1) {
        pages.push('...');
      }

      // Always show the last page
      if (totalPages > 1) {
        pages.push(totalPages);
      }
    }

    return pages;
  },
  showPrevious() {
    return this.currentPage > 1;
  },
  showNext() {
    return this.currentPage < this.totalPages;
  }
  },
  methods: {
    getLocationNameById(locationId) {
      const location = this.masterLocations.find(loc => loc.locationid === locationId);
      return location ? location.Name : 'Unknown';
    },
    goToPage(page) {
    if (page === '...' || page === this.currentPage) return;
    this.currentPage = page;
  },
    async fetchMasterLocations() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');

      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.get('/master_location_list/', config);
        this.Area = response.data[2];

        // Create a lookup map for location names
        
    console.log('Location Map:', this.locationMap);
      } catch (error) {
        console.error('Error fetching Master Locations:', error);
        this.error = 'Error fetching Master Locations. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    formatDateToDDMMYYYY(dateStr) {
      const [year, month, day] = dateStr.split('-');
      return `${day}-${month}-${year}`;
    },
    formatDateToYYYYMMDD(dateStr) {
      const [day, month, year] = dateStr.split('-');
      return `${year}-${month}-${day}`;
    },
    updateImpRotDate(event) {
      this.newVcn.ImpRotDate = this.formatDateToYYYYMMDD(event.target.value);
    },
    async fetchVcns() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');

      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.get('/vcn_list/', config);
        this.vcns = response.data[2].map(vcn => ({
          ...vcn,
          ImpRotDate: this.formatDate(vcn.ImpRotDate),
          ExpRotDate: this.formatDate(vcn.ExpRotDate),
          BerthDate: this.formatDate(vcn.BerthDate),
          EgmDate: this.formatDate(vcn.EgmDate),
          SailDate: this.formatDate(vcn.SailDate),
        }));
        console.log('VCNs:', this.vcns);
      } catch (error) {
        console.error('Error fetching VCN list:', error);
        this.error = 'Error fetching VCN list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },

    async checkVcnName(name) {
      if (name.trim() === '') {
        this.nameError = '';
        return;
      }

      try {
        const vcnExists = this.vcns.some(vcn => vcn.vcnName.toLowerCase() === name.toLowerCase());
        if (vcnExists) {
          this.nameError = 'VCN name already exists.';
        } else {
          this.nameError = '';
        }
      } catch (err) {
        console.error('Error checking VCN name:', err);
      }
    },

    formatDate(dateString) {
      if (!dateString) {
    return ''; // Or return 'N/A' or any other placeholder
  }
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });
    },

    validateVcnName() {
      if (this.vcns.includes(this.newVcn.vcnName)) {
        this.vcnNameError = 'VCN Name must be unique.';
      } else {
        this.vcnNameError = '';
      }
    },

    checkPermissions() {
      const token = localStorage.getItem('access_token');
      this.hasPermissions = !!token;
    },

    openAddOverlay() {
      this.isAddingNew = true;
      this.isEditing = false;
      this.selectedVcn = null;
      this.resetNewVcn();
    },

    cancelAdd() {
    this.isAddingNew = false;
    this.isEditing = false;
    this.selectedVcn = null;
    this.resetNewVcn();
  },
formatDateFromDDMMYYYY(dateStr) {
  const [day, month, year] = dateStr.split('-');
  return `${year}-${month}-${day}`;
},
    async saveVcn() {
      const toast = useToast();
      if (this.nameError !== '') {
        toast.error('Please fix the errors before saving.');
        return;
      }
      try {
        const token = localStorage.getItem('access_token');

        if (!token) {
          console.error('No token found in localStorage');
          return;
        }

        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const formattedVcn = { ...this.newVcn };
    formattedVcn.ImpRotDate = this.newVcn.ImpRotDate ? this.formatDateToYYYYMMDD(this.formatDateFromDDMMYYYY(this.newVcn.ImpRotDate)) : null;
    formattedVcn.ExpRotDate = this.newVcn.ExpRotDate ? this.formatDateToYYYYMMDD(this.formatDateFromDDMMYYYY(this.newVcn.ExpRotDate)) : null;
    formattedVcn.BerthDate = this.newVcn.BerthDate ? this.formatDateToYYYYMMDD(this.formatDateFromDDMMYYYY(this.newVcn.BerthDate)) : null;
    formattedVcn.EgmDate = this.newVcn.EgmDate ? this.formatDateToYYYYMMDD(this.formatDateFromDDMMYYYY(this.newVcn.EgmDate)) : null;
    formattedVcn.SailDate = this.newVcn.SailDate ? this.formatDateToYYYYMMDD(this.formatDateFromDDMMYYYY(this.newVcn.SailDate)) : null;
        console.log(formattedVcn.EgmDate)
        if (this.isEditing) {
          await axios.put(`/update_vcn/${this.selectedVcn.vcnID}/`, formattedVcn, config);
          toast.success('VCN updated successfully.');
          this.resetNewVcn();
        } else {
          await axios.post('/create_vcn/', this.newVcn, config);
          this.reset
          toast.success('VCN added successfully.');
          this.resetNewVcn();
        }

        this.fetchVcns();
        this.cancelAdd();
      } catch (error) {
        console.error('Error saving VCN:', error);
        const toast = useToast();
        toast.error('Error saving VCN.');
      }
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      console.log(this.showExportOptions);
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    editVcn(vcn) {
    this.selectedVcn = vcn;
    this.newVcn = { ...vcn };
    this.isEditing = true;
    this.isAddingNew = false;
  },

  cancelEdit() {
    this.isAddingNew = false;
    this.isEditing = false;
    this.selectedVcn = null;
    this.resetNewVcn();
  },

    deleteVcn(vcn) {
      const confirmed = confirm('Are you sure you want to delete this VCN?');
      if (confirmed) {
        this.removeVcn(vcn);
      }
    },

    async removeVcn(vcn) {
      try {
        const token = localStorage.getItem('access_token');

        if (!token) {
          console.error('No token found in localStorage');
          return;
        }

        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        await axios.delete(`/delete_vcn/${vcn.vcnID}/`, config);
        this.fetchVcns();
        const toast = useToast();
        toast.success('VCN deleted successfully.');
      } catch (error) {
        console.error('Error deleting VCN:', error);
        const toast = useToast();
        toast.error('Error deleting VCN.');
      }
    },

    handlePageChange(page) {
      this.currentPage = page;
    },

    handleRowsPerPageChange(rowsPerPage) {
      this.rowsPerPage = rowsPerPage;
      this.currentPage = 1;
    },
    resetNewVcn() {
    this.newVcn = {
      vcnNo: '',
      vcnName: '',
      vesselID: null,
      VesselName: '',
      import_voyage: null,
      import_voyageName: '',
      export_voyage: null,
      export_voyageName: '',
      MlocID: null,
      area: '',
      ImpRotNo: '',
      ImpRotDate: null,
      ExpRotNo: '',
      ExpRotDate: null,
      BerthDate: null,
      EgmDate: '',
      IsSail: false,
      SailDate: null,
      vesselLocation: null,
      Vesselarea: null,
    };
  },
    exportToCSV() {
      const csvContent = this.paginatedVcns.map((vcn) => {
        return [
          vcn.vcnName,
          vcn.VesselName,
          vcn.import_voyageName,
          vcn.export_voyageName,
          vcn.area,
          vcn.ImpRotNo,
          vcn.ImpRotDate,
          vcn.ExpRotNo,
          vcn.ExpRotDate,
          vcn.BerthDate,
          vcn.EgmDate,
          vcn.IsSail ? 'Yes' : 'No',
          vcn.SailDate,
          this.locationMap[vcn.vesselLocation],
        ].join(',');
      }).join('\n');

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'vcn_list.csv';
      link.click();
    },

    exportToPDF() {
      // Create a new jsPDF instance
      const doc = new jsPDF();
      const header = 'Exported VCN Data';

      // Set the font size for the header
      doc.setFontSize(16);
      doc.text(header, 10, 10);

      // Set the font size for the body text
      doc.setFontSize(12);
      let yOffset = 20;

      // Check if vcns is defined and is an array
      if (Array.isArray(this.vcns) && this.vcns.length > 0) {
        // Loop through each item in vcns
        this.vcns.forEach(item => {
          // Format the text for each VCN entry
          const text = `VCN: ${item.vcnName || 'N/A'}, Vessel: ${item.VesselName || 'N/A'}, Import Voyage: ${item.import_voyageName || 'N/A'}, Export Voyage: ${item.export_voyageName || 'N/A'}, Status: ${item.IsSail ? 'Sailed' : 'Not Sailed'}`;
          doc.text(text, 10, yOffset);
          yOffset += 10; // Increment yOffset for the next line
        });
      } else {
        // If no data is available, notify in the PDF
        doc.text('No VCN data available to export.', 10, yOffset);
      }

      // Save the PDF with a specific filename
      doc.save('vcn_list.pdf');

      // Close the export options dropdown
      this.showExportOptions = false;
    }
  },
};
</script>



<style scoped>
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
}


.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the button */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}
.vcn-list {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.add-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover {
  background-color: rgb(61, 146, 231);
}

.add-button span {
  display: none;
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  bottom: 150%;
}

.add-button:hover span {
  display: block;
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
  margin-left: 10px;
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}
.error-message {
  color: #d9534f; /* Bootstrap's danger color */
  font-size: 12px;
  margin-bottom: 4px; /* Space between the error message and input field */
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5; /* Light red background */
  display: inline-block;
}
.export-options {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-align: left;
  top: 40px;
  border-radius: 10px;
}

.export-button:hover .export-options {
  display: block;
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 10px;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}
.error {
  color: red;
  font-size: 12px;
  margin-left: 10px;
}
.vcn-table {
  width: 100%;
  border-collapse: collapse;
}

.vcn-table th,
.vcn-table td {
  min-width: 120px;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.vcn-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.vcn-table th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}

.new-row input {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-table {
  display: flex;
  align-items: center;
}

.rows-per-table span {
  margin-right: 10px;
}

.rows-per-table button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333333;
  font-size: 14px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.rows-per-table button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.pagination button {
  width: 35px;
  height: 35px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 15px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
}

.pagination button.active {
  background-color: rgb(50, 89, 187);
  color: white;
}
.export-container {
  position: relative;
  /* Add this */
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  position: relative;
  height: 32px;
  margin-left: 10px;
  /* Space between buttons */
}

.export-options {
  display: block;
  top: 100%;
  /* Update this to be relative to the .export-container */
  right: 0;
  /* Update this to be relative to the .export-container */
  /* ... existing styles ... */
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}
</style>
