<template>
  <div class="main">
    <div class="link-party-list">
      <h1 class="title">Link Party List</h1>

      <div v-if="isLoading">Loading Link Party list...</div>
      <div v-else-if="error">Error loading Link Party list: {{ error }}</div>

      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <div class="actions">
            <button class="add-button" @click="openAddOverlay" title="Add New Link Party"
              :disabled="isEditing || isAddingNew">
              <i class="fas fa-plus"></i>
            </button>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>

        </div>

        <div class="table-container">
          <table class="link-party-table">
            <thead>
              <tr>
                <th>Short Name</th>
                <th>Applicant Name</th>
                <th>Account Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <!-- Add Row -->
              <tr v-if="isAddingNew" class="new-row">
                <td>
                  <div v-if="shortNameError" class="error-message">{{ shortNameError }}</div>
                  <input v-model="newParty.short_name" placeholder="Short Name" />
                </td>
                <td>
                  <v-select :clearable="false" v-model="newParty.applicantId" :options="applicants"
                    label="applicantname" :reduce="applicant => applicant.applicantid" placeholder="Select Applicant"
                    append-to-body></v-select>
                </td>
                <td>
                  <v-select :clearable="false" v-model="newParty.accountId" :options="accounts" label="accountname"
                    :reduce="account => account.accountid" placeholder="Select Account" append-to-body></v-select>
                </td>
                <td>
                  <button @click="saveParty" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>

              <!-- Existing Rows -->
              <tr v-for="(party) in paginatedParties" :key="party.autoid"
                :class="{ 'editing': party === selectedParty }">
                <td v-if="party === selectedParty">
                  <div v-if="shortNameError" class="error-message">{{ shortNameError }}</div>
                  <div></div>
                  <input v-model="newParty.short_name" placeholder="Short Name" />
                </td>
                <td v-else>{{ party.short_name }}</td>

                <td v-if="party === selectedParty">
                  <v-select :clearable="false" v-model="newParty.applicantId" :options="applicants"
                    label="applicantname" :reduce="applicant => applicant.applicantid" placeholder="Select Applicant"
                    append-to-body></v-select>
                </td>
                <td v-else>{{ getApplicantName(party.applicantId) }}</td>

                <td v-if="party === selectedParty">
                  <v-select :clearable="false" v-model="newParty.accountId" :options="accounts" label="accountname"
                    :reduce="account => account.accountid" placeholder="Select Account" append-to-body></v-select>
                </td>
                <td v-else>{{ getAccountName(party.accountId) }}</td>

                <td>
                  <button v-if="party === selectedParty" @click="updateParty" class="save-button"><i
                      class="fas fa-save"></i></button>
                  <button v-if="party === selectedParty" @click="cancelEdit" class="cancel-button"><i
                      class="fas fa-times"></i></button>
                  <button v-else @click="editParty(party)" class="edit-button" :disabled="isAddingNew"><i
                      class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-page">
            <button @click="setRowsPerPage(10)">10</button>
            <button @click="setRowsPerPage(20)">20</button>
            <button @click="setRowsPerPage(30)">30</button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="currentPage === 1">&lt;</button>
            <button v-for="page in totalPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="currentPage === totalPages">&gt;</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      parties: [],
      showExportOptions:false,
      isLoading: false,
      error: null,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      isAddingNew: false,
      selectedParty: null,
      newParty: {
        short_name: '',
        applicantId: null,
        accountId: null,
      },
      shortNameError: '',
      applicants: JSON.parse(localStorage.getItem('applicants')) || [],
      accounts: JSON.parse(localStorage.getItem('accounts')) || [],
    };
  },
  created() {
    this.fetchLinkParties();
  },
  computed: {
    filteredParties() {
      return this.parties.filter(party => party.short_name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    paginatedParties() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      return this.filteredParties.slice(start, start + this.rowsPerPage);
    },
    totalPages() {
      return Math.ceil(this.filteredParties.length / this.rowsPerPage);
    },
    isEditing() {
      return this.selectedParty !== null;
    },
  },
  watch: {
    'newParty.short_name': async function (newShortName) {
      this.shortNameError = '';
      const isUnique = await this.checkShortNameUniqueness(newShortName, this.selectedParty?.autoid);
      if (!isUnique) {
        this.shortNameError = 'Short name already exists. Please enter a unique short name.';
      }
    },
  },
  methods: {
    async fetchLinkParties() {
      this.isLoading = true;
      this.error = null;
      const token = localStorage.getItem('access_token');

      try {
        const config = {
          headers: {
             'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };
        const response = await axios.get('/linkParties', config);
        this.parties = response.data[2];
      } catch (error) {
        console.error('Error fetching link parties:', error);
        this.error = 'Error fetching link parties. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    openAddOverlay() {
      if (this.isEditing) {
        this.cancelEdit();
      }
      this.isAddingNew = true;
      this.resetNewParty();
    },
    resetNewParty() {
      this.newParty = {
        short_name: '',
        applicantId: null,
        accountId: null,
      };
      this.shortNameError = '';
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      console.log(this.showExportOptions);
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    async checkShortNameUniqueness(shortName, autoid = 0) {
      const exists = this.parties.some(party => party.short_name.toLowerCase() === shortName.toLowerCase() && party.autoid !== autoid);
      return !exists;
    },
    async saveParty() {
      const toast = useToast();
      this.shortNameError = '';

      if (!(await this.checkShortNameUniqueness(this.newParty.short_name))) {
        this.shortNameError = 'Short name already exists. Please enter a unique short name.';
        toast.error(this.shortNameError);
        return;
      }

      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };

        await axios.post('/createpartylink', this.newParty, config);
        toast.success('Link Party Created Successfully!');
        this.fetchLinkParties();
        this.isAddingNew = false;
        this.selectedParty = null;
      } catch (error) {
        console.error('Error saving link party:', error);
        toast.error('Error saving link party. Check console for details.');
      }
    },
    async editParty(party) {
      this.newParty = { ...party };
      this.selectedParty = party;
    },
    async updateParty() {
      const toast = useToast();
      this.shortNameError = '';

      if (!(await this.checkShortNameUniqueness(this.newParty.short_name, this.selectedParty.autoid))) {
        this.shortNameError = 'Short name already exists. Please enter a unique short name.';
        toast.error(this.shortNameError);
        return;
      }

      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        };

        await axios.put(`/updatepartylink/${this.selectedParty.autoid}`, this.newParty, config);
        toast.success('Link Party Updated Successfully!');
        this.fetchLinkParties();
        this.selectedParty = null;
      } catch (error) {
        console.error('Error updating link party:', error);
        toast.error('Error updating link party. Check console for details.');
      }
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.resetNewParty();
    },
    cancelEdit() {
      this.selectedParty = null;
      this.resetNewParty();
    },
    setRowsPerPage(number) {
      this.rowsPerPage = number;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    getApplicantName(applicantId) {
      const applicant = this.applicants.find(app => app.applicantid === applicantId);
      return applicant ? applicant.applicantname : '';
    },
    getAccountName(accountId) {
      const account = this.accounts.find(acc => acc.accountid === accountId);
      return account ? account.accountname : '';
    },
  },
};
</script>




<style scoped>
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
}

.link-party-list {
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.table-container {
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.link-party-table {
  width: 100%;
  border-collapse: collapse;
}

.link-party-table th,
.link-party-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.link-party-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}

.new-row input,
.new-row .v-select {
  width: 100%;
  padding: 5px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.rows-per-page {
  display: flex;
  align-items: center;
}

.rows-per-page button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 14px;
  border-radius: 30px;
  cursor: pointer;
}

.rows-per-page button:hover {
  background-color: rgb(50, 89, 187);
  color: white;
}

.pagination {
  display: flex;
  align-items: center;
  
}

.pagination button {
  margin-left: 5px;
  padding: 5px 10px;
  border: none;
  background-color: #f2f2f2;
  color: #333;
  font-size: 14px;
  border-radius: 20px;
  cursor: pointer;
}

.pagination button:hover {
  background-color: rgb(50, 89, 187);
  color: white;
}

.error-message {
  color: #d9534f;
  font-size: 12px;
  margin-bottom: 4px;
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  display: inline-block;
}

.editing {
  background-color: #e0f7fa; /* Light blue background for editing row */
}
.export-container {
  position: relative;
  /* Add this */
}

.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  position: relative;
  height: 32px;
  margin-left: 10px;
  /* Space between buttons */
}

.export-options {
  display: block;
  top: 100%;
  /* Update this to be relative to the .export-container */
  right: 0;
  /* Update this to be relative to the .export-container */
  /* ... existing styles ... */
}

.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.add-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover {
  background-color: rgb(61, 146, 231);
}
</style>