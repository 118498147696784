<template>
  <div class="main">
    <div class="repair-list">
      <h1 class="title">Repair List</h1>

      <div v-if="isLoading" class="loading">Loading Repair list...</div>
      <div v-else-if="error" class="error">Error loading Repair list: {{ error }}</div>

      <div v-else>
        <div class="controls">
          <div class="search-container">
            <input v-model="searchQuery" placeholder="Search..." />
            <i class="fas fa-search search-icon"></i>
          </div>
          <div class="actions">
            <div class="tooltip-container" @mouseenter="showAddTooltip = true" @mouseleave="showAddTooltip = false">
              <button class="add-button" @click="openAddOverlay" title="Add New Repair" :disabled="selectedRepair">
                <i class="fas fa-plus"></i>
                <div v-if="showAddTooltip" class="tooltip">Add New Repair</div>
              </button>
            </div>
            <div class="export-container">
              <button class="export-button" @click="toggleExportOptions" title="Export">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ul v-if="showExportOptions" class="export-options">
                <li @click="exportToCSV">Export to CSV</li>
                <li @click="exportToPDF">Export to PDF</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="table-container">
          <table class="repair-table">
            <thead>
              <tr>
                <th>
                  <span>Description</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('description')"></i>
                </th>
                <th>
                  <span>Short Name</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('shortName')"></i>
                </th>
                <th>
                  <span>Status</span>
                  <i class="filter-icon fas fa-sort" @click="sortByColumn('isActive')"></i>
                </th>
                <th v-if="hasPermissions">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isAddingNew" class="new-row">
                <td>
                  <div v-if="descriptionError" class="error-message">{{ descriptionError }}</div>
                  <input v-model="newRepair.description" placeholder="Description" />
                </td>
                <td><input v-model="newRepair.shortName" placeholder="Short Name" /></td>
                <td>
                  <select v-model="newRepair.isActive">
                    <option :value="0">Inactive</option>
                    <option :value="1">Active</option>
                  </select>
                </td>
                <td v-if="hasPermissions">
                  <button @click="saveRepair" class="save-button"><i class="fas fa-save"></i></button>
                  <button @click="cancelAdd" class="cancel-button"><i class="fas fa-times"></i></button>
                </td>
              </tr>
              <tr v-for="repair in paginatedRepairs" :key="repair.autoid" :class="{ 'editing': repair === selectedRepair }">
                <td class="row-edit" v-if="repair === selectedRepair">
                  <div v-if="descriptionError" class="error-message">{{ descriptionError }}</div>
                  <input v-model="selectedRepair.description" placeholder="Description" />
                </td>
                <td v-else>{{ repair.description }}</td>
                <td class="row-edit" v-if="repair === selectedRepair">
                  <input v-model="selectedRepair.shortName" placeholder="Short Name" />
                </td>
                <td v-else>{{ repair.shortName }}</td>
                <td class="row-edit" v-if="repair === selectedRepair">
                  <select v-model="selectedRepair.isActive">
                    <option :value="0">Inactive</option>
                    <option :value="1">Active</option>
                  </select>
                </td>
                <td v-else>{{ repair.isActive ? 'Active' : 'Inactive' }}</td>
                <td v-if="hasPermissions">
                  <button v-if="repair === selectedRepair" @click="saveRepair(repair)" class="save-button"><i class="fas fa-save"></i></button>
                  <button v-if="repair === selectedRepair" @click="cancelEdit" class="cancel-button"><i class="fas fa-times"></i></button>
                  <button v-else @click="editRepair(repair)" class="edit-button"><i class="fas fa-edit"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="bottom-controls">
          <div class="rows-per-table">
            <button v-for="option in [10, 20, 30]" :key="option" :class="{ 'active': rowsPerPage === option }"
              @click="setRowsPerPage(option)">
              {{ option }}
            </button>
          </div>
          <div class="pagination">
            <button @click="goToPage(currentPage - 1)" :disabled="!showPrevious"> &lt; </button>
            <button v-for="page in paginatedPages" :key="page" @click="goToPage(page)"
              :class="{ active: page === currentPage }">
              {{ page }}
            </button>
            <button @click="goToPage(currentPage + 1)" :disabled="!showNext"> &gt; </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { useToast } from 'vue-toastification';

export default {
  data() {
    return {
      repairs: [],
      descriptionError: '',
      isLoading: false,
      error: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      isAddingNew: false,
      selectedRepair: null,
      showExportOptions: false,
      newRepair: {
        autoid: 0,
        description: '',
        shortName: '',
        isActive: 0,
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      },
      sortDirection: {
        description: 'asc',
        shortName: 'asc',
        isActive: 'asc',
      },
      showAddTooltip: false,
    };
  },
  created() {
    this.fetchRepairs();
    this.checkPermissions();
  },
  computed: {
    resetRepair() {
      return {
        autoid: 0,
        description: '',
        shortName: '',
        isActive: 0,
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      };
    },
    filteredRepairs() {
      const query = this.searchQuery.toLowerCase();
      return this.repairs
        .filter(repair => 
          Object.values(repair).some(value =>
            String(value).toLowerCase().includes(query)
          )
        )
        .sort((a, b) => {
          for (const key in this.sortDirection) {
            if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
            if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
          }
          return 0;
        });
    },
    paginatedRepairs() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      return this.filteredRepairs.slice(start, start + this.rowsPerPage);
    },
    totalPages() {
      return Math.ceil(this.filteredRepairs.length / this.rowsPerPage);
    },
    paginatedPages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      const maxPagesToShow = 10;
      let pages = [];

      if (totalPages <= maxPagesToShow) {
        pages = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        pages.push(1);
        let start = Math.max(2, currentPage - 4);
        let end = Math.min(totalPages - 1, currentPage + 4);

        if (start > 2) {
          pages.push('...');
        }

        for (let i = start; i <= end; i++) {
          pages.push(i);
        }

        if (end < totalPages - 1) {
          pages.push('...');
        }

        if (totalPages > 1) {
          pages.push(totalPages);
        }
      }

      return pages;
    },
    showPrevious() {
      return this.currentPage > 1;
    },
    showNext() {
      return this.currentPage < this.totalPages;
    }
  },
  watch: {
    'newRepair.description'(newDescription) {
      if (this.isAddingNew || (this.selectedRepair && this.newRepair.description !== this.selectedRepair.description)) {
        this.checkRepairDescription(newDescription);
      }
    },
    searchQuery() {
      this.currentPage = 1;
    },
  },
  methods: {
    goToPage(page) {
      if (page === '...' || page === this.currentPage) return;
      this.currentPage = page;
    },
    showTooltip() {
      this.showAddTooltip = true;
    },
    hideTooltip() {
      this.showAddTooltip = false;
    },
    async checkRepairDescription(description) {
      if (description.trim() === '') {
        this.descriptionError = '';
        return;
      }

      try {
        const repairExists = this.repairs.some(repair => repair.description.toLowerCase() === description.toLowerCase() &&
          repair.autoid !== this.newRepair.autoid);

        this.descriptionError = repairExists ? 'Repair description already exists.' : '';
      } catch (err) {
        console.error('Error checking repair description:', err);
      }
    },
    getCurrentUserId() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user ? user.user_id : 0;
    },
    toggleExportOptions() {
      this.showExportOptions = !this.showExportOptions;
      document.addEventListener('click', this.handleOutsideClick);
    },
    handleOutsideClick(event) {
      if (!event.target.closest('.export-container')) {
        this.showExportOptions = false;
        document.removeEventListener('click', this.handleOutsideClick);
      }
    },
    async fetchRepairs() {
      this.isLoading = true;
      this.error = null;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        this.isLoading = false;
        return;
      }

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        const response = await axios.get('/repair_list', config);
        this.repairs = response.data[2];
        localStorage.setItem('repairs', JSON.stringify(response.data[2]));
      } catch (error) {
        console.error('Error fetching repair list:', error);
        this.error = 'Error fetching repair list. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    async checkPermissions() {
      this.hasPermissions = true; // Set this according to your logic
    },
    openAddOverlay() {
      if (this.selectedRepair) {
        this.cancelEdit(); // Cancel editing if a repair is currently selected
      }
      this.isAddingNew = true; // Open add overlay
      this.resetNewRepair(); // Reset new repair fields
    },
    async saveRepair() {
      const toast = useToast();
      if (this.descriptionError !== '') {
        toast.error('Please fix the errors before saving.');
        return;
      }

      const token = localStorage.getItem('access_token');
      const userId = this.getCurrentUserId();
      const now = new Date().toISOString();

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        };

        

        if (this.isAddingNew) {
            const payload = {
          ...this.newRepair,
          created_by: userId,
          last_modified_by: userId,
          CreateDate: now,
          ModiDate: now,
        };
          await axios.post('/create_repair', payload, config);
          toast.success('Repair Created Successfully!');
        } else if (this.selectedRepair) {
            const payload = {
          ...this.selectedRepair,
          created_by: userId,
          last_modified_by: userId,
          CreateDate: now,
          ModiDate: now,
        };
          await axios.put(`/update_repair/${this.selectedRepair.autoid}`, payload, config);
          toast.success('Repair Updated Successfully!');
        }

        this.fetchRepairs();
        this.isAddingNew = false;
        this.selectedRepair = null;
        this.newRepair = this.resetRepair;
      } catch (error) {
        console.error('Error saving repair:', error);
        toast.error('Error saving repair. Check console for details.');
      }
    },
    resetNewRepair() {
      this.newRepair = {
        autoid: 0,
        description: '',
        shortName: '',
        isActive: 0,
        created_by: 0,
        CreateDate: '',
        last_modified_by: 0,
        ModiDate: '',
      };
    },
    cancelAdd() {
      this.isAddingNew = false;
      this.selectedRepair = null;
    },
    editRepair(repair) {
      if (this.isAddingNew) {
        this.cancelAdd(); // Cancel adding if currently adding
      }
      this.selectedRepair = repair;
      this.newRepair = { ...repair };
    },
    async cancelEdit() {
      this.selectedRepair = null;
      this.resetNewRepair();
    },
    exportToCSV() {
      const csvContent = [
        ['Description', 'Short Name', 'Status'],
        ...this.repairs.map(repair => [repair.description, repair.shortName, repair.isActive ? 'Active' : 'Inactive']),
      ]
      .map(e => e.join(","))
      .join("\n");

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'repair_list.csv';
      link.click();
    },
    exportToPDF() {
      // Implement PDF export logic here
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    setRowsPerPage(rows) {
      this.rowsPerPage = rows;
      this.currentPage = 1; // Reset to first page when changing rows per page
    },
  },
};
</script>


<style scoped>
.main {
  width: 100%; /* Make the main container take up full width */
  margin: 0 auto; /* Center the page horizontally */
  max-width: 1200px; /* Set the maximum width */
  padding: 20px; /* Add some padding */
  box-sizing: border-box; /* Include padding in the width calculation */
}

.repair-list {
  padding: 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  color: #333;
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-container {
  position: relative;
}

.search-container input {
  padding: 8px 30px 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.error-message {
  color: #d9534f;
  font-size: 12px;
  margin-bottom: 4px;
  padding: 4px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  background-color: #f9d6d5;
  display: inline-block;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #666;
  cursor: pointer;
}

.actions {
  display: flex;
  align-items: center;
}

.add-button,
.export-button {
  background-color: rgb(50, 89, 187);
  color: white;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: relative;
}

.add-button:hover,
.export-button:hover {
  background-color: rgb(61, 146, 231);
}

.export-container {
  position: relative;
}

.export-options {
  display: block;
  top: 100%;
  right: 0;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.export-options li {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  cursor: pointer;
}

.export-options li:hover {
  background-color: #ddd;
}

.table-container {
  width: 100%; /* Ensure the container takes full width */
  max-height: 500px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  min-width: 800px; /* Set a minimum width for the table container */
}

.repair-table {
  width: 100%; /* Ensure the table takes full width of the container */
  border-collapse: collapse;
  table-layout: fixed; /* Make the table take up full width */
}

.repair-table th, .repair-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  flex: 1; /* Make the columns take up equal widths */
  box-sizing: border-box; /* Include padding in the width calculation */
  min-width: 150px; /* Set a minimum width for each column */
}

.repair-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  color: #333;
}

.repair-table td {
  background-color: #fff;
}

.repair-table th .filter-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
}

.repair-table tr:hover {
  background-color: #f1f1f1;
}

.edit-button,
.save-button,
.cancel-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.save-button {
  color: green;
}

.cancel-button {
  color: red;
}

.row-edit input{
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.row-edit select{
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.new-row input {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.new-row select {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-input {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-input:focus {
  border-color: #aaa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination li {
  list-style: none;
  margin: 0 5px;
}

.pagination li a {
  color: #337ab7;
  text-decoration: none;
  padding: 8px 12px;
  border: 1 px solid #ddd;
  border-radius: 50%;
  cursor: pointer;
}

.pagination li a:hover {
  background-color: #337ab7;
  color: white;
}

.pagination li.active a {
  background-color: #337ab7;
  color: white;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

</style>
