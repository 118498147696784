import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.css'; // Import FontAwesome CSS
import store from './store' // Import your Vuex store
import Toast, { POSITION } from 'vue-toastification';
import axios from 'axios'; // Import Axios
import 'vue-toastification/dist/index.css';


axios.defaults.baseURL = 'http://103.120.178.70:8000';

const app = createApp(App);
app.config.globalProperties.$axios = axios;

// Use store, router, toast notifications, and Axios
app.use(store);
app.use(Toast, {
  position: POSITION.TOP_RIGHT, // Adjust position if needed
});
app.use(router); // Bind Axios to Vue

// Mount the app
app.mount('#app');