import axios from 'axios';

const state = {
  applicants: [],
};

const mutations = {
  SET_APPLICANTS(state, applicants) {
    state.applicants = applicants;
  },
};

const actions = {
  async fetchApplicants({ commit }) {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error('No token found');
      }
      const response = await axios.get('http://103.120.178.70:8000/applicant_list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_APPLICANTS', response.data);
    } catch (error) {
      console.error('Error fetching applicants:', error);
    }
  },
};

const getters = {
  applicants: (state) => state.applicants,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
