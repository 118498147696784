<template>
  <div class="main">
    <div class="transaction-list">
      <h1 class="title">Damage Entry</h1>

      <div class="form-group">
        <div class="form-item">
          <label for="partyname">Party *</label>
          <v-select :clearable="false" placeholder="Select Party Name" id="partyname" v-model="newContainer.partyName"
            :options="party" label="short_name" :reduce="party => party.autoid" />


        </div>

        <div class="form-item">
          <label for="vesselName">Vessel *</label>
          <v-select :clearable="false" id="vesselName" placeholder="Select Vessel" v-model="newContainer.vesselName"
            :options="vessel" label="Name" :reduce="vessel => vessel.Vesselid" />
        </div>

        <div class="form-item">
          <label for="voyageName">Voyage *</label>
          <v-select :clearable="false" id="voyageName" v-model="newContainer.voyageName" :options="voyage" label="Name"
            :reduce="voyage => voyage.Voyageid" placeholder="Select Voyage" />
        </div>

        <div class="form-item">
          <label for="movementname">Movement Type *</label>
          <v-select :clearable="false" id="movementname" v-model="newContainer.movementID" :options="movementOptions"
            label="Name" :reduce="movement => movement.Movid" placeholder="Select Movement Name" />
        </div>

        <div class="form-item">
          <label for="clearingAgent">Clearing Agent:</label>
          <v-select :clearable="false" id="clearingAgent" placeholder="Select Clearing Agent" :options="clearingAgents"
            v-model="newContainer.clearingAgent" />
        </div>
      </div>

      <div v-if="showOverlay" class="overlay">
        <div class="overlay-content">
          <h2>Damage Details</h2>
          <form @submit.prevent="saveDamageDetails">
            <div class="overlay-form-group">
              <label for="location">Location:</label>
              <v-select :clearable="false" style="width: 250px;" id="location" v-model="damageDetails.location"
                :options="locations" label="Name" class="overlay-form-select" placeholder="Select Location" />
              <label for="position">Position:</label>
              <v-select :clearable="false" style="width: 250px;" id="position" v-model="damageDetails.position"
                :options="positions" placeholder="Select Position" label="name" class="overlay-form-select" />

            </div>
            <div class="overlay-form-group">
              <label for="damageFrom">Damage From:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageFrom" placeholder="Select Damage From"
                v-model="damageDetails.damageFrom" :options="damageFromOptions" label="name" class="overlay-form-select"
                append-to-body />

              <label for="damageFrom">&</label>
              <v-select :clearable="false" style="width: 250px;" id="damageFrom" v-model="damageDetails.damageFromAnd"
                :options="damageFromOptions" label="name" class="overlay-form-select" append-to-body />

            </div>

            <div class="overlay-form-group">
              <label for="damageTo">Damage To:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageTo" v-model="damageDetails.damageTo"
                :options="damageFromOptions" label="name" class="overlay-form-select" append-to-body
                placeholder="Select Damage To" />

              <label for="damageTo">&:</label>
              <v-select :clearable="false" style="width: 250px;" id="damageTo" v-model="damageDetails.damageToAnd"
                :options="damageToOptions" label="name" class="overlay-form-select" append-to-body />

            </div>

            <div class="overlay-form-group">
              <label style="width:100px" for="component">Component:</label>
              <v-select :clearable="false" style="width: 120px;" id="component" v-model="damageDetails.component"
                :options="components" label="name" :reduce="component => component.id" class="overlay-form-input"
                placeholder="Select Component" />

              <label for="damageType">Damage Type:</label>
              <v-select :clearable="false" style="width: 120px;" id="damageType" v-model="damageDetails.damageType"
                :options="damageTypes" label="name" :reduce="damageType => damageType.autid" class="overlay-form-input"
                placeholder="Select Damage Type " />

              <label for="repairType">Repair Type:</label>
              <v-select :clearable="false" style="width: 120px;" id="repairType" v-model="damageDetails.repairType"
                :options="repairTypes" label="name" :reduce="repairType => repairType.id" class="overlay-form-input"
                placeholder="Select Repair Type" />
            </div>
            <div class="overlay-form-group">
              <label for="length">Length:</label>
              <input style="width: 60px;" type="number" value="0" id="length" v-model="damageDetails.length"
                class="overlay-form-input" />
              <label for="breadth">Breadth:</label>
              <input style="width: 60px;" type="number" id="breadth" v-model="damageDetails.breadth"
                class="overlay-form-input" />
              <label for="area">Area:</label>
              <input style="width: 60px;" type="number" id="area" value="0" v-model="damageDetails.area"
                class="overlay-form-input" />
              <label for="quantity">Quantity:</label>
              <input style="width: 60px;" type="number" id="quantity" value="0" v-model="damageDetails.quantity"
                class="overlay-form-input" />
            </div>

            <button type="submit">Save</button>
            <button class="cancel-button" @click="closeOverlay">Cancel</button>
          </form>

          <!-- Table to display saved damage details -->
          <div v-if="damageDetailsList.length > 0">
            <table class="damage-details-table">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Position</th>
                  <th>Damage From</th>
                  <th>Damage To</th>
                  <th>Component</th>
                  <th>Damage Type</th>
                  <th>Repair Type</th>
                  <th>Length</th>
                  <th>Breadth</th>
                  <th>Area</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(detail, index) in damageDetailsList" :key="index">
                  <td>{{ detail.location.Name }}</td>
                  <td>{{ detail.position.name }}</td>
                  <td>{{ detail.damageFrom.name }}</td>
                  <td>{{ detail.damageTo.name }}</td>
                  <td>{{ detail.component }}</td>
                  <td>{{ detail.damageType }}</td>
                  <td>{{ detail.repairType }}</td>
                  <td>{{ detail.length }}</td>
                  <td>{{ detail.breadth }}</td>
                  <td>{{ detail.area }}</td>
                  <td>{{ detail.quantity }}</td>
                </tr>
              </tbody>
            </table>
            <button class="done-button" @click="done">Done</button>
          </div>
        </div>
      </div>


    </div>
    <div v-if="isLoading">Loading transactions...</div>
    <div v-else-if="error">Error loading transactions: {{ error }}</div>

    <div v-else>
      <div class="table-container">
        <table class="movement-table">
          <thead>
            <tr>
              <th>Container Number</th>
              <th>Size</th>
              <th>Type</th>

              <th>Tare Weight</th>
              <th>Gross Weight</th>
              <th>Year of MFG</th>
              <th>Seal No</th>
              <th>Inspection Date</th>
              <th>Inspection Time</th>

              <th>Place</th>
              <th>Place To</th>
              <th>Booking No</th>
              <th v-if="shouldShowCFSID">CFS ID</th>
              <th v-if="shouldShowPOL">POL</th>
              <th v-if="shouldShowPOD">POD</th>
              <th v-if="shouldShowFPD">FPD</th>
              <th>HAZ STAT</th>
              <th>Note</th>
              <th v-if="shouldShowSoundDamage">Sound/Damage</th>
            </tr>
          </thead>
          <tbody>
            <tr class="add-row">
              <td><input v-model="newContainer.contnumber" placeholder="Container Number" ref="containerInput"
                  @blur="LoadContainerData" /></td>
              <td>
                <v-select :clearable="false" v-model="newContainer.size" :options="[20,40]" placeholder="Select Size" />
              </td>
              <td>
                <v-select :clearable="false" v-model="newContainer.type" :options="containerTypes" label="typetext"
                  :reduce="type => type.autoid" placeholder="Select Type" />
              </td>

              <td><input v-model="newContainer.tareWeight" placeholder="Tare Weight" /></td>
              <td><input type="number" v-model="newContainer.GrossWt" placeholder="Gross Weight" /></td>
              <td><input v-model="newContainer.yearOfMFG" placeholder="Year of MFG" /></td>
              <td><input v-model="newContainer.sealNo" placeholder="Seal No" /></td>
              <td><input type="date" :max="maxDate" v-model="newContainer.inspDate" /></td>
              <td><input type="text" v-model="newContainer.insptTime" /></td>

              <td>
                <v-select :clearable="false" v-model="newContainer.place" :options="location" label="Name"
                  :reduce="location => location.locationid" placeholder="Select PlaceTo" append-to-body />

              </td>
              <td>
                <v-select :clearable="false" v-model="newContainer.placeTo" :options="location" label="Name"
                  :reduce="location => location.locationid" placeholder="Select PlaceTo" append-to-body />

              </td>

              <td>
                <input v-model="newContainer.bookingNo" placeholder="Booking No" />
              </td>
              <td v-if="shouldShowCFSID">
                <input v-model="newContainer.cfsid" placeholder="CFS ID" />
              </td>
              <td v-if="shouldShowPOL">
                <v-select :clearable="false" v-model="newContainer.pol" :options="ports" label="name"
                  :reduce="port => port.portid" placeholder="Select POL" />
              </td>
              <td v-if="shouldShowPOD">
                <v-select :clearable="false" v-model="newContainer.pod" :options="ports" label="name"
                  :reduce="port => port.portid" placeholder="Select POL" />
              </td>
              <td v-if="shouldShowFPD">
                <v-select :clearable="false" v-model="newContainer.fpd" :options="ports" label="name"
                  :reduce="port => port.portid" placeholder="Select POL" />
              </td>
              <td>
                <v-select :clearable="false" v-model="newContainer.hazStat" :options="hazStatOptions" label="label"
                  :reduce="option => option.value" placeholder="Select Hazard Status" append-to-body />
              </td>
              <td>
                <textarea v-model="newContainer.note" placeholder="Note" rows="3"></textarea>
              </td>
              <td v-if="shouldShowSoundDamage">
                <div class="sound-damage-container">
                  <label>
                    <input type="radio" v-model="newContainer.soundDamage" value="Sound"
                      @keypress.enter="handleEnterKey" />
                    Sound
                  </label>
                  <label>
                    <input type="radio" v-model="newContainer.soundDamage" value="Damage"
                      @keypress.enter="handleEnterKey" />
                    Damage
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>




<script>
import axios from 'axios';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      transactions: [],
      party: [],
      accounts: JSON.parse(localStorage.getItem('accounts')) || [],
      applicants: JSON.parse(localStorage.getItem('applicants')) || [],
      vessel: JSON.parse(localStorage.getItem('vessel')) || [],
      voyage: JSON.parse(localStorage.getItem('voyage')) || [],
      movementOptions: JSON.parse(localStorage.getItem('movements')) || [],
      ports: JSON.parse(localStorage.getItem('ports')) || [],
      
      location: JSON.parse(localStorage.getItem('location')) || [],
      locations: [
        { id: 1, Name: 'EXT' },
        { id: 2, Name: 'INT' },],
      positions: [
        { id: 1, name: 'Left' },
        { id: 2, name: 'Front' },
        { id: 3, name: 'Right' },
        { id: 4, name: 'Top' },
        { id: 5, name: 'Rear' },
        { id: 6, name: 'Interior' },
        { id: 7, name: 'Under Structure' },
        { id: 8, name: 'Exterior' },
      ],
      damageFromOptions: [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
        { id: 33, name: 'Top' },
        { id: 34, name: 'Bottom' },
        { id: 35, name: 'Left' },
        { id: 36, name: 'Right' },
      ],

      damageToOptions: [
        { id: 1, name: '1' },
        { id: 2, name: '2' },
        { id: 3, name: '3' },
        { id: 4, name: '4' },
        { id: 5, name: '5' },
        { id: 6, name: '6' },
        { id: 7, name: '7' },
        { id: 8, name: '8' },
        { id: 9, name: '9' },
        { id: 10, name: '10' },
        { id: 11, name: '11' },
        { id: 12, name: '12' },
        { id: 13, name: '13' },
        { id: 14, name: '14' },
        { id: 15, name: '15' },
        { id: 16, name: '16' },
        { id: 17, name: '17' },
        { id: 18, name: '18' },
        { id: 19, name: '19' },
        { id: 20, name: '20' },
        { id: 21, name: '21' },
        { id: 22, name: '22' },
        { id: 23, name: '23' },
        { id: 24, name: '24' },
        { id: 25, name: '25' },
        { id: 26, name: '26' },
        { id: 27, name: '27' },
        { id: 28, name: '28' },
        { id: 29, name: '29' },
        { id: 30, name: '30' },
        { id: 31, name: 'Left' },
        { id: 32, name: 'Right' },
        { id: 33, name: 'Top' },
        { id: 34, name: 'Bottom' },
        { id: 35, name: 'Left' },
        { id: 36, name: 'Right' },
      ],
      clearingAgents: ['Agent A', 'Agent B', 'Agent C'], // Static options
      reportTypes: ['Survey', 'Comparator', 'Non-EOR'],
      movement: [],
      nameError: '',
      isLoading: false,
      error: null,
      clearingAgent: null,
      damageTypes: [],
      repairTypes: [],
      components: [],
      reportType: null,
      hasPermissions: false,
      searchQuery: '',
      rowsPerPage: 10,
      currentPage: 1,
      selectedTransaction: null,
      newContainer: {
        autoID: 0,
        vcnID: 0,
        vcnName: '',
        applicantID: null,
        applicantname: '',
        accountID: null,
        accountname: '',
        vesselID: null,
        VesselName: '',
        VoyId: null,
        VoyageName: '',
        containerID: null,
        contnumber: '',
        movementID: null,
        MovementName: '',
        locID: null,
        LocationName: '',
        blNo: null,
        cfsID: 0,
        sealNo: '',
        pol: 0,
        pod: 0,
        fpd: 0,
        MtLdStat: '',
        GrossWt: 0,
        m_haulage: 0,
        note: null,
        movetype: '',
        inspDate: '',
        inspTime: '',
        damage: '',
        hazStat: 'N'
      },
      containerTypes: [], 
      containerSizes: [
        { value: 20, text: '20' },
        { value: 40, text: '40' },
      ],
      successfulTasks: [],
      hazStatOptions: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
      ],
      newTransaction: {
        contnumber: '',
        contsize: '',
        conttype: '',
        TypeText: '',
        construction: '',
        iso_code: '',
        isotarewt: '',
        isogrosswt: '',
        applicantname: '',
        accountname: '',
        VesselName: '',
        Colsign: '',
        VoyageName: '',
        MovementName: '',
        LocationName: '',
      },
      sortDirection: {
        contnumber: 'asc',
        contsize: 'asc',
        conttype: 'asc',
        TypeText: 'asc',
        construction: 'asc',
        iso_code: 'asc',
        isotarewt: 'asc',
        isogrosswt: 'asc',
        applicantname: 'asc',
        accountname: 'asc',
        VesselName: 'asc',
        Colsign: 'asc',
        VoyageName: 'asc',
        MovementName: 'asc',
        LocationName: 'asc',
      },
      damageDetails: {
        location: '',
        position: '',
        damageFrom: '',
        damageFromAnd: '',
        damageTo: '',
        damageToAnd: '',
        component: '',
        damageType: '',
        repairType: '',
        length: 0,
        breadth: 0,
        area: 0,
        quantity: 0
      },
      damageDetailsList: [],
      showOverlay: false,
      maxDate: new Date().toISOString().split('T')[0], // Current date for date inputs
    };
  },
  created() {
    this.checkPermissions();
    this.fetchDropdownData();
    this.fetchContainerTypes();
    this.fetchData();
  },
  computed: {
    resetTransaction() {
      return {
        contnumber: '',
        contsize: '',
        conttype: '',
        TypeText: '',
        construction: '',
        iso_code: '',
        isotarewt: '',
        isogrosswt: '',
        applicantname: '',
        accountname: '',
        VesselName: '',
        Colsign: '',
        VoyageName: '',
        MovementName: '',
        LocationName: '',
      };
    },
    filteredTransactions() {
      return this.transactions.filter(transaction => {
        return Object.values(transaction).some(value =>
          String(value).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }).sort((a, b) => {
        for (const key in this.sortDirection) {
          if (a[key] < b[key]) return this.sortDirection[key] === 'asc' ? -1 : 1;
          if (a[key] > b[key]) return this.sortDirection[key] === 'asc' ? 1 : -1;
        }
        return 0;
      });
    },
    shouldShowISPTDate() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowTime() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowGrossWeight() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowCFSID() {
      return ['23', '24', '25', '42', '43'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOL() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowFPD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowSoundDamage() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    paginatedTransactions() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.filteredTransactions.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredTransactions.length / this.rowsPerPage);
    },
  },
  methods: {
  
    async fetchContainerTypes() {
      const token = localStorage.getItem('access_token');

      try {
        const response = await axios.get('http://103.120.178.70:8000/container_type', {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        // Assuming the response structure is [1, "Data Found", [{...}, {...}]]
        this.containerTypes = response.data[2]; // Extracting the container types from the response
      } catch (error) {
        console.error('Error fetching container types:', error);
      }
    },
    async fetchLinkParties() {
      this.isLoading = true;
      this.error = null;
      const token = localStorage.getItem('access_token');

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
          },
        };
        const response = await axios.get('/linkParties', config);
        this.party = response.data[2]; // Assuming the link parties are in the third element of the response array
      } catch (error) {
        console.error('Error fetching link parties:', error);
        this.error = 'Error fetching link parties. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    handleEnterKey(event) {
    // Check if the Enter key was pressed
    if (event.key === 'Enter') {
      if (this.newContainer.soundDamage === 'Sound') {
        alert('Saved');
      } else if (this.newContainer.soundDamage === 'Damage') {
        this.openDamageOverlay();
      }
    }
  },
    async fetchDropdownData() {
      try {
        const token = localStorage.getItem('access_token');
        const config = { headers: { 
          'accept': 'application/json',
          'Authorization': `Bearer ${token}` } };

        const partyRes = await axios.get('/linkParties', config);
        this.party = partyRes.data[2];

        const vessRes = await axios.get('/vessel_list', config);
        this.vessels = vessRes.data[2];

        const voyRes = await axios.get('/voyage_list', config);
        this.voyage = voyRes.data[2];

        

        const moveRes = await axios.get('/movement_list', config);
        this.movementOptions = moveRes.data[2];
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    },
    fetchData() {
      const accessToken = localStorage.getItem('access_token');

      axios.get('/damage_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const damageTypesResponse = response.data[2];
          this.damageTypes = damageTypesResponse.map(damageType => ({
            id: damageType.autoid,
            name: damageType.shortName,
            description: damageType.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });

      axios.get('/repair_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const repairTypesResponse = response.data[2];
          this.repairTypes = repairTypesResponse.map(repairType => ({
            id: repairType.autoid,
            name: repairType.shortName,
            description: repairType.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });

      axios.get('/component_list', {
        headers: {
          accept: 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
      })
        .then(response => {
          const componentsResponse = response.data[2];
          this.components = componentsResponse.map(component => ({
            id: component.autoid,
            name: component.shortName,
            description: component.description,
          }));
        })
        .catch(error => {
          console.error(error);
        });
    },
    checkPermissions() {
      const user = JSON.parse(localStorage.getItem('user'));
      this.hasPermissions = user && user.role === 'admin';
    },
    editTransaction(transaction) {
      this.selectedTransaction = transaction;
      this.newTransaction = { ...transaction };
    },
    async saveTransaction() {
      if (this.nameError) return;

      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }

      try {
        const response = await axios.post('/add_transaction', this.newTransaction, {
          headers: { 
            'accept': 'application/json',
            'Authorization': `Bearer ${token}` },
        });
        const index = this.transactions.findIndex(t => t.reportid === response.data[2].reportid);
        if (index !== -1) {
          this.transactions.splice(index, 1, response.data[2]);
        } else {
          this.transactions.push(response.data[2]);
        }
        this.selectedTransaction = null;
        this.newTransaction = { ...this.resetTransaction };
      } catch (err) {
        this.error = 'Error saving transaction';
        console.error(err);
      }
    },
    cancelEdit() {
      this.selectedTransaction = null;
      this.newTransaction = { ...this.resetTransaction };
    },
    setRowsPerPage(option) {
      this.rowsPerPage = option;
    },
    goToPage(page) {
      this.currentPage = page;
    },
    sortByColumn(column) {
      this.sortDirection[column] = this.sortDirection[column] === 'asc' ? 'desc' : 'asc';
    },
    openDamageOverlay() {
      this.showOverlay = true;
    },
    closeOverlay() {
      this.showOverlay = false;
    },
    saveDamageDetails() {
      this.damageDetailsList.push({ ...this.damageDetails });
      // Clear the form fields after saving
      this.damageDetails = {
        location: '',
        position: '',
        damageFrom: '',
        damageTo: '',
        component: '',
        damageType: '',
        repairType: '',
        length: 0,
        breadth: 0,
        area: 0,
        quantity: 0
      };
    },
    done() {
      this.closeOverlay();
    },
    async fetchContainerData() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        this.error = 'No token found in localStorage';
        return;
      }

      try {
        const response = await axios.get(`/container_data/${this.newContainer.contnumber}`, {
          headers: { 
            'accept': 'application/json',
            'Authorization': `Bearer ${token}` }
        });
        // Process response to update `newContainer`
        this.newContainer = { ...response.data };
      } catch (error) {
        console.error('Error fetching container data:', error);
        this.error = 'Error fetching container data. Check console for details.';
      }
    }
  },
};
</script>


<style scoped>

/* Main container styling */
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
  padding: 20px;
}

/* Header styling */
.title {
  font-size: 24px;
  margin-bottom: 20px;
}


/* Form group styling for form elements */
.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 15px;
}

.form-item{
  width:30%;
}



select, input[type="text"], input[type="date"], input[type="number"], v-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 16px;
}

.excel-button {
  background-color: #17a2b8;
  color: white;
}

.generate-button {
  background-color: #28a745;
  color: white;
}

.cancel-button1 {
  background-color: #dc3545;
  color: white;
}

button:hover {
  opacity: 0.8;
}

/* Tooltip styling */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px); /* Add background blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Allow scrolling if content overflows */
  z-index: 1000; /* Ensure overlay is on top of other content */
}

/* Overlay content styling */
.overlay-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 60%; /* Allow more width */
  max-height: 90%; /* Restrict height to avoid overflow */
  overflow-y: auto; /* Scroll if content overflows vertically */
  z-index: 1001; /* Ensure content is above overlay background */
}

/* Overlay form group styling */
/* Overlay form group styling */
.overlay-form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Add space between form groups */
  gap: 5px; /* Add space between fields */
}

/* Styling for labels to ensure alignment */
.overlay-form-group label {
  margin-right: 2px;
  width: 70px; /* Set a fixed width for labels */
}

/* Styling for input and select elements to take available space */

.overlay-form-group .overlay-form-select {
  width: 350px;
  /* adjust the width to your desired size */
  font-size: 12px;
  /* adjust the font size to your desired size */
}
.cancel-button{
  background-color: #dc3545;
}
.cancel-button:hover {
  background-color: #f68677;
}
/* Ensure v-select placeholder is visible */
.v-select .vs__placeholder {
  color: #aaa; /* Change placeholder color if needed */
  overflow: visible; /* Ensure placeholder is visible if content overflows */
}



/* Table in the main view */
/* Table container styling */
.table-container {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  height:1000px;
}

/* Styling for the movement table */
.movement-table {
  width: 1500px; /* Set a fixed width for the table */
  min-width: 3000px;
  border-collapse: collapse; /* Remove spaces between cells */
  background-color: #fff;
}

/* Table header styling */
.movement-table th {
  padding: 10px 0px;
  background-color: #f0f0f0;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  text-align: left;
  border-bottom: 2px solid #ccc;
  width: 100px; /* Set a minimum width for each column */
}

/* Table body styling */
.movement-table td {
  padding: 10px 2px;
  font-size: 14px;
  color: #555;
  border-bottom: 1px solid #e0e0e0; /* Add border between rows */
}

/* Button row at the bottom */
.add-row input {
  width: 80%;
  padding: 12px;
  font-size: 14px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Button styling */
button {
  padding: 10px 15px;
  border: none;
  background-color: #28a745;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}
.sound-damage-container {
  display: flex; /* Use flexbox to align the radio buttons */
  flex-direction: row; /* Align the radio buttons horizontally */
  align-items: center; /* Vertically center the radio buttons */
}

.sound-damage-container label {
  margin-right: 10px; /* Add some space between the radio buttons */
}

.sound-damage-container input[type="radio"] {
  margin-right: 5px; /* Add some space between the radio button and its label */
}
button:hover {
  background-color: #218838;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .movement-table th, .movement-table td {
    font-size: 12px; /* Reduce font size on smaller screens */
  }

  .table-container {
    height: auto; /* Allow the table to grow in height */
  }
}


/* Table styling */
.damage-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.damage-details-table th {
  padding: 10px 15px;
  background-color: #f0f0f0;
  font-weight: bold;
  font-size: 14px;
  color: #333;
  text-align: left;
  border-bottom: 2px solid #ccc;
}

.damage-details-table td {
  padding: 10px 15px;
  font-size: 14px;
  color: #555;
  border-bottom: 1px solid #e0e0e0;
}

/* Button styling */
.done-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745;
  color: #fff;
  font-size: 16px;
}

.done-button:hover {
  background-color: #218838;
}
</style>
