<template>
  <div class="main">
    <div class="container-list">
      <h1 class="title">Advanced Container List</h1>

      <div v-if="isLoading">Loading Container list...</div>
      <div v-else-if="error">Error loading Container list: {{ error }}</div>

      <div v-else>
        <div class="table-container">
          <table class="movement-table">
            <thead>
              <tr>
                <th>Container Number</th>
                <th>Party Name</th>
                <th>Vessel Name</th>
                <th>Voyage Name</th>
                <th>Movement Name</th>
                <th>Location Name</th>
                <th>Seal No</th>
                <th v-if="shouldShowISPTDate">ISPT Date</th>
                <th v-if="shouldShowTime">Time</th>
                <th v-if="shouldShowGrossWeight">Gross Weight</th>
                <th v-if="shouldShowCFSID">CFS ID</th>
                <th v-if="shouldShowPOL">POL</th>
                <th v-if="shouldShowPOD">POD</th>
                <th v-if="shouldShowFPD">FPD</th>
                <th>HAZ STAT</th>
                <th>Note</th>
                <th v-if="shouldShowSoundDamage">Sound/Damage</th>
              </tr>
            </thead>
            <tbody>
              <!-- Add Row -->
              <tr class="add-row">
                <!-- Your input fields here -->
                <td><input v-model="newContainer.contnumber" placeholder="Container Number" ref="containerInput"
                    @blur="LoadContainerData" /></td>
                <td>
                  <select style="width: 100px; padding: 0; height: 40px;" v-model="selectedShortName"
                    @change="handleChange">
                    <option value="">Select Party</option>
                    <option v-for="party in linkParties" :key="party.autoid" :value="party.autoid">
                      {{ party.short_name }}
                    </option>
                  </select>

                </td>
                <td>
                  <v-select :clearable="false" v-model="newContainer.vesselID" :options="vessels" label="Name"
                    :reduce="vessel => vessel.Vesselid" placeholder="Select a Vessel Name" />
                </td>
                <td>
                  <v-select :clearable="false" v-model="newContainer.VoyId" :options="voyage" label="Name"
                    :reduce="voyage => voyage.Voyageid" placeholder="Select a Voyage Name" />
                </td>
                <td>
                  <v-select :clearable="false" v-model="newContainer.movementID" :options="movementOptions" label="Name"
                    :reduce="movement => movement.Movid" placeholder="Select Movement Name" />
                </td>
                <td>
                  <v-select :clearable="false" v-model="newContainer.locID" :options="locations" label="Name"
                    :reduce="locations => locations.locationid" placeholder="Select a Location Name" />
                </td>
                <td><input v-model="newContainer.sealNo" placeholder="Seal No" /></td>
                <td v-if="shouldShowISPTDate">
                  <input style="width:120px" type="date" :max="maxDate" v-model="newContainer.inspDate" />
                </td>
                <td v-if="shouldShowTime"><input style="padding:0; width: 50px;" type="text"
                    v-model="newContainer.inspTime" />
                </td>
                <td v-if="shouldShowGrossWeight"><input style="width: 60px;height: 40px;" type="number"
                    v-model="newContainer.GrossWt" placeholder="Gross Weight" /></td>
                <td v-if="shouldShowCFSID">
                  <v-select :clearable="false" v-model="newContainer.cfsID" :options="locations" label="Name"
                    :reduce="location => location.locationid" placeholder="Select CFS ID" />
                </td>
                <td v-if="shouldShowPOL">
                  <v-select :clearable="false" v-model="newContainer.pol" :options="ports" label="name"
                    :reduce="port => port.portid" placeholder="Select POL" />
                </td>
                <td v-if="shouldShowPOD">
                  <v-select :clearable="false" v-model="newContainer.pod" :options="ports" label="name"
                    :reduce="port => port.portid" placeholder="Select POD" />
                </td>
                <td v-if="shouldShowFPD">
                  <v-select :clearable="false" v-model="newContainer.fpd" :options="ports" label="name"
                    :reduce="port => port.portid" placeholder="Select FPD" />
                </td>
                <td>
                  <select style="width: 50px; padding: 0;height: 40px;" v-model="newContainer.hazStat"
                    placeholder="Select Hazard Status">
                    <option disabled value="">Select Hazard Status</option>
                    <option v-for="option in hazStatOptions" :key="option.value" :value="option.value">
                      {{ option.label }}
                    </option>
                  </select>
                </td>
                <td><textarea v-model="newContainer.note" placeholder="Note" rows="4"></textarea></td>
                <td v-if="shouldShowSoundDamage">
                  <div class="sound-damage-buttons">
                    <div>
                      <input type="radio" id="sound" value="sound" v-model="newContainer.damage"
                        @keydown.enter="handleCondition('Sound')">
                      <label for="sound">Sound</label>
                    </div>
                    <div>
                      <input type="radio" id="damage" value="damage" v-model="newContainer.damage"
                        @keydown.enter="handleCondition('Damage')">
                      <label for="damage">Damage</label>
                    </div>
                  </div>
                </td>
              </tr>
              <!-- Last 5 Successful Tasks -->
              <tr v-for="container in lastFiveSuccessfulTasks" :key="container.autoID" class="successful-task">
                <td>{{ container.contnumber }}</td>
                <td>{{ container.short_name }}</td>
                <td>{{ container.vesselName }}</td>
                <td>{{ container.voyageName }}</td>
                <td>{{ container.movementName }}</td>
                <td>{{ container.locationName }}</td>
                <td>{{ container.sealNo }}</td>
                <td v-if="shouldShowISPTDate">{{ container.inspDate }}</td>
                <td v-if="shouldShowTime">{{ container.inspTime }}</td>
                <td v-if="shouldShowGrossWeight">{{ container.GrossWt }}</td>
                <td v-if="shouldShowCFSID">{{ container.cfsID }}</td>
                <td v-if="shouldShowPOL">{{ container.pol }}</td>
                <td v-if="shouldShowPOD">{{ container.pod }}</td>
                <td v-if="shouldShowFPD">{{ container.fpd }}</td>
                <td>{{ container.hazStat }}</td>
                <td>{{ container.note }}</td>
                <td v-if="shouldShowSoundDamage">{{ container.damage }}</td>
              </tr>
              <!-- Container List -->
              <tr v-for="(container) in paginatedContainers" :key="container.autoID">
                <td>{{ container.contnumber }}</td>
                <td></td>
                <td>{{ container.VesselName }}</td>
                <td>{{ container.VoyageName }}</td>
                <td>{{ container.MovementName }}</td>
                <td>{{ container.LocationName }}</td>
                <td>{{ container.sealNo }}</td>
                <td v-if="shouldShowISPTDate">{{ container.inspDate }}</td>
                <td v-if="shouldShowTime">{{ container.inspTime }}</td>
                <td v-if="shouldShowGrossWeight">{{ container.GrossWt }}</td>
                <td v-if="shouldShowCFSID">{{ container.cfsID }}</td>
                <td v-if="shouldShowPOL">{{ container.pol }}</td>
                <td v-if="shouldShowPOD">{{ container.pod }}</td>
                <td v-if="shouldShowFPD">{{ container.fpd }}</td>
                <td>{{ container.hazStat }}</td>
                <td>{{ container.note }}</td>
                <td v-if="shouldShowSoundDamage">{{ container.damage }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { useToast } from 'vue-toastification';


export default {
  components: {
    VSelect,
  },
  data() {
    return {
      containerdata: [],
      containers: [],
      linkParties: [],
      selectedShortName:'',
      accounts: JSON.parse(localStorage.getItem('accounts')) || [],
      applicants: JSON.parse(localStorage.getItem('applicants')) || [],
      vessels: JSON.parse(localStorage.getItem('vessel')) || [],
      voyage: JSON.parse(localStorage.getItem('voyage')) || [],
      movementOptions: JSON.parse(localStorage.getItem('movements')) || [],
      ports: JSON.parse(localStorage.getItem('ports')) || [],
      locations: JSON.parse(localStorage.getItem('locations')) || [],
      isLoading: false,
      error: null,
      hasPermissions: true,
      rowsPerPage: 10,
      currentPage: 1,
      newContainer: {
        autoID: 0,
        vcnID: 0,
        vcnName: '',
        applicantID: null,
        applicantname: '',
        accountID: null,
        accountname: '',
        vesselID: null,
        VesselName: '',
        VoyId: null,
        VoyageName: '',
        containerID: null,
        contnumber: '',
        movementID: null,
        inspectionId:null,
        MovementName: '',
        locID: null,
        short_name:'',
        LocationName: '',
        blNo: null,
        cfsID: null,
        sealNo: '',
        pol: null,
        pod: null,
        fpd:  null,
        MtLdStat: '',
        GrossWt: 0,
        m_haulage: 0,
        note: null,
        movetype: '',
        inspDate: this.getCurrentDate(),
        inspTime: '',
        damage: '',
        hazStat: 'N'
      },
      successfulTasks: [],
      hazStatOptions: [
        { label: 'Yes', value: 'Y' },
        { label: 'No', value: 'N' }
      ],
    };
  },

  computed: {
    maxDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    lastFiveSuccessfulTasks() {
      console.log(this.successfulTasks);
      return this.successfulTasks.slice(-5).reverse();
    },
    paginatedContainers() {
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return this.containers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.containers.length / this.rowsPerPage);
    },
    shouldShowISPTDate() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowTime() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowGrossWeight() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowCFSID() {
      return ['23', '24', '25', '42', '43'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOL() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowPOD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowFPD() {
      return ['30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    },
    shouldShowSoundDamage() {
      return ['3', '23', '24', '25', '42', '43', '30', '31', '34', '36', '40', '41', '7', '177'].includes(String(this.newContainer.movementID));
    }
  },
  created() {
    this.fetchLinkParties(); // Fetch link parties
  },
  methods: {
    async LoadContainerData() {
      const contnum = this.newContainer.contnumber
      if (contnum == "")
        return;
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
          },
        };
        const response = await axios.get(`/getContainerDetails?containerno=${contnum}`, config);
        this.containerdata = response.data[2];
        console.log(this.containerdata)
        this.selectedShortName=this.containerdata.PartyLinkId
        this.newContainer.containerID = this.containerdata.containerId
        this.newContainer.vesselID = this.containerdata.vesselld
        this.newContainer.VoyId = this.containerdata.voyage
        this.newContainer.movementID = this.containerdata.movementId
        this.newContainer.locID = this.containerdata.inspectionplace
        this.newContainer.containerID = this.containerdata.containerID
      }
      catch (error) {
        console.error('Error creating transaction:', error);
        this.error = 'Error creating transaction. Check console for details.';
      }
    },
    async fetchLinkParties() {
      this.isLoading = true;
      this.error = null;
      const token = localStorage.getItem('access_token');

      try {
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
          },
        };
        const response = await axios.get('/linkParties', config);
        this.linkParties = response.data[2]; // Assuming the link parties are in the third element of the response array
      } catch (error) {
        console.error('Error fetching link parties:', error);
        this.error = 'Error fetching link parties. Check console for details.';
      } finally {
        this.isLoading = false;
      }
    },
    updateNewContainerIds(value) {
      console.log(value,'1');
      const selectedParty = this.linkParties.find(party => party.autoid === Number(value));
  console.log(selectedParty)
  if (selectedParty) {
    this.newContainer.applicantID = selectedParty.applicantId;
    this.newContainer.short_name = selectedParty.short_name;
    
    this.newContainer.accountID = selectedParty.accountId;
  
  } else {
    this.newContainer.applicantID = 0;
    this.newContainer.accountID = 0;
  }
},
    validateRequiredFields() {
      if (!this.newContainer.contnumber || !this.newContainer.VesselName || !this.newContainer.VoyageName) {
        useToast().error('Please fill in the Container Number, Vessel Name, and Voyage Name fields.');
        return false;
      }
      return true;
    },
    
    handleChange(event) {
      const selectedShortName = event.target.value;
      this.updateNewContainerIds(selectedShortName);
    },
    getNameById(array, id, key = 'id', nameKey = 'name') {
      const item = array.find(item => item[key] === id);
      return item ? item[nameKey] : '';
    },
    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    async handleCondition(conditionType) {
      if  (!this.newContainer.contnumber || !this.newContainer.vesselID || !this.newContainer.VoyId || !this.newContainer.movementID || !this.newContainer.locID || !this.newContainer.inspDate || !this.newContainer.inspTime || !this.newContainer.hazStat) {
        useToast().error('Please fill in the Container Number, Vessel Name, and Voyage Name fields before selecting Sound or Damage.');
        return;
      }
      const party = this.linkParties.find(party => party.autoid === this.selectedShortName).short_name;
      console.log(party)
      const conditionId = conditionType === 'Sound' ? 1 : 2;
      // Create transaction data with correct property names and values
      console.log(this.newContainer.applicantID);
      const transactionData = {
        containerId: this.newContainer.containerID || 0,
        containerNumber: this.newContainer.contnumber || '',
        applicant: this.newContainer.applicantID || 0,
        account: this.newContainer.accountID || 0,
        vesselld: this.newContainer.vesselID || 0,
        voyage: this.newContainer.VoyId || 0,
        movementId: this.newContainer.movementID || 0,
        locationId: this.newContainer.locID || 0,
        cfsId: this.newContainer.cfsID || 0,
        cfsName: this.newContainer.cfsName, // Use saved name
        seal: this.newContainer.sealNo || '',
        inspdate: this.newContainer.inspDate || this.getCurrentDate(),
        insptime: this.newContainer.inspTime || '',
        conditionid: conditionId,
        inspectionplace : this.newContainer.locID,
        condition: conditionType,
        note: this.newContainer.note || '',
        cargowt: this.newContainer.GrossWt || 0,
        hazstat: this.newContainer.hazStat || 'N',
        loadport: this.newContainer.pol || 0,
        loadPortName: this.newContainer.loadPortName, // Use saved name
        destnport: this.newContainer.pod || 0,
        destnPortName: this.newContainer.destnPortName, // Use saved name
        transport: this.newContainer.fpd || 0,
        transportName: this.newContainer.transportName, // Use saved name
      };
      const vesselName = this.getNameById(this.vessels, this.newContainer.vesselID, 'Vesselid', 'Name');
      const voyageName = this.getNameById(this.voyage, this.newContainer.VoyId, 'Voyageid', 'Name');
      const locationName = this.getNameById(this.locations, this.newContainer.locID, 'locationid', 'Name');
      const movementName = this.getNameById(this.movementOptions, this.newContainer.movementID, 'Movid', 'Name');

      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`, // Include the token in the headers
          },
        };
        const response = await axios.post('/create_transaction', transactionData, config);
        if (response.data[0] === 0) {
            // Display success message using the second element of the response
            useToast().error(response.data[1]);
        } else {
          useToast().success('Created Succesful');
          this.successfulTasks.push({ ...this.newContainer, conditionType,
            sealNo: this.newContainer.sealNo,
            note: this.newContainer.note,
            vesselName,
            voyageName,
            locationName,
            movementName,
            short_name: party
           });
          console.log(this.successfulTasks,'hello')
          this.resetNewContainer();
        }
        
      } catch (error) {
        console.error('Error creating transaction:', error);
        this.error = 'Error creating transaction. Check console for details.';
      }
    },
    addContainer() {
      this.handleCondition('Sound');
    },

    resetNewContainer() {
      this.newContainer = {
        autoID: 0,
        vcnID: 0,
        vcnName: '',
        applicantID: null,
        applicantname: '',
        accountID: null,
        accountname: '',
        vesselID: null,
        VesselName: '',
        VoyId: null,
        VoyageName: '',
        containerID: null,
        contnumber: '',
        movementID: null,
        MovementName: '',
        locID: null,
        LocationName: '',
        blNo: null,
        cfsID: 0,
        sealNo: '',
        pol: 0,
        pod: 0,
        fpd: 0,
        MtLdStat: '',
        GrossWt: 0,
        m_haulage: 0,
        note: null,
        movetype: '',
        inspDate: this.getCurrentDate(),
        inspTime: '',
        damage: '',
        hazStat: 'N'
      };
    },
    setRowsPerPage(rows) {
      this.rowsPerPage = rows;
      this.currentPage = 1;
    },
  },
};
</script>


<style scoped>
.main {
  background-color: #f0f2f5;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  width: 100%;
  height: 50%;
}

.container-list {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background-color: #f0f2f5;
  border-radius: 20px;
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
  /* Center the container */
}

.sound-damage-buttons {
  display: flex;
  flex-direction: column;
  /* Align buttons vertically */
  gap: 10px;
  /* Space between the button groups */
}

.sound-damage-buttons div {
  display: flex;
  align-items: center;
  /* Align radio button and label vertically */
  margin-bottom: 8px;
  /* Space between individual radio button groups */
}

.sound-damage-buttons input[type="radio"] {
  margin-right: 5px;
  /* Reduced space between radio button and label */
  accent-color: #007bff;
  /* Custom color for the radio button */
}

.sound-damage-buttons label {
  font-size: 16px;
  /* Adjust font size for better readability */
  margin: 0;
  /* Remove extra margin around the label */
}

/* Optional: Adjust the size of radio buttons if necessary */
.sound-damage-buttons input[type="radio"] {
  width: 16px;
  /* Size of the radio button */
  height: 16px;
  /* Size of the radio button */
}



.table-container {
  overflow-x: auto;
  border-radius: 20px;
  height: 400px;
  width: auto;
}

.movement-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  /* Center the table */
  border: 1px solid #ddd;
  /* Add border to the table */
}

.movement-table th,
.movement-table td {
  padding: 2px 4px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  /* Border for table rows */
}

.movement-table th {
  background-color: #f8f8f8;
}

.movement-table tr:hover {
  background-color: #f1f1f1;
}

.successful-task {
  background-color: #e0ffe0;
  /* Light green background for successful tasks */
}

.add-row {
  background-color: #e8f0fe;
  /* Light blue background for the add row */
}

.bottom-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.rows-per-page {
  display: flex;
  align-items: center;
}

.rows-per-page span {
  margin-right: 8px;
}

.rows-per-page button {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0 5px;
}

.rows-per-page button.active {
  background-color: #007bff;
  color: #fff;
}



.add-row input,
.add-row select {
  width: 150px;
  /* Full width of the parent element minus padding */
  padding: 8px 12px;
  /* Increased padding for better usability */
  margin-bottom: 8px;
  /* Increased margin for better spacing */
  box-sizing: border-box;
}

.v-select {
  width: 150px;
}

.add-row input[type="date"],
.add-row input[type="time"] {
  padding: 2px 2px;
  width: 100px;
  /* Increased padding for better usability */
}
</style>