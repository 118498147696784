<template>
  <div class="dashboard">
    <h1>Dashboard</h1>
    
  </div>
</template>



<style scoped>
.dashboard {
  padding: 20px;
}
.widgets {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
</style>
