<template>
  <div class="upload-content-tile">
    <h1 class="title">Upload Content</h1>

    <div class="form-row">
      <label for="selectedShortName" class="form-label">Party Name</label>
      <select v-model="selectedShortName" @change="handleChange" class="custom-select">
        <option value="">Select Party</option>
        <option v-for="party in linkParties" :key="party.autoid" :value="party.autoid">
          {{ party.short_name }}
        </option>
      </select>
    </div>
    <div class="form-row">
      <label for="movement" class="form-label">Movement</label>
      <select id="movement" v-model="formData.movement" class="custom-select">
        <option value="" disabled>Select Movement</option>
        <option v-for="movement in movements" :key="movement.Movid" :value="movement.Name">
          {{ movement.Name }}
        </option>
      </select>
    </div>
    <div class="form-row">
      <label for="location" class="form-label">Location</label>
      <select id="location" v-model="formData.location" class="custom-select">
        <option value="" disabled>Select Location</option>
        <option v-for="location in locations" :key="location.locationid" :value="location.Name">
          {{ location.Name }}
        </option>
      </select>
    </div>

    <div class="form-row">
      <label for="vesselName" class="form-label">Vessel Name</label>
      <select id="vesselName" v-model="formData.vesselName" class="custom-select">
        <option value="" disabled>Select Vessel Name</option>
        <option v-for="vessel in vessels" :key="vessel.Vesselid" :value="vessel.Name">
          {{ vessel.Name }}
        </option>
      </select>
    </div>

    <div class="form-row">
      <label for="voyageName" class="form-label">Voyage Name</label>
      <select id="voyageName" v-model="formData.voyageName" class="custom-select">
        <option value="" disabled>Select Voyage Name</option>
        <option v-for="voyage in voyages" :key="voyage.Voyageid" :value="voyage.Name">
          {{ voyage.Name }}
        </option>
      </select>
    </div>

    <div class="form-row">
      <label for="file" class="form-label">Select File</label>
      <input type="file" id="file" @change="handleFileUpload" class="custom-file-input">
    </div>

    <div class="form-actions">
      <button @click="uploadFile">Upload File</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';

export default {
  name: 'UploadContent',
  setup() {
    const linkParties = ref([]);
    const movements = ref([]);
    const locations = ref([]);
    const vessels = ref([]);
    const voyages = ref([]);
    const formData = ref({
      applicantName: '',
      accountName: '',
      movement: '',
      location: '',
      vesselName: '',
      voyageName: '',
      file: null,
    });

    onMounted(() => {
      fetchDropdownData();
    });

    const fetchDropdownData = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const config = {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };

        const response = await axios.get('/linkParties', config);
        linkParties.value = response.data[2]; // Assuming the link parties are in the third element of the response array

        const movementRes = await axios.get('/movement_list/', config);
        movements.value = movementRes.data[2];

        const locationRes = await axios.get('/location_list/', config);
        locations.value = locationRes.data[2];

        const vesselRes = await axios.get('/vessel_list/', config);
        vessels.value = vesselRes.data[2];

        const voyageRes = await axios.get('/voyage_list/', config);
        voyages.value = voyageRes.data[2];

        //console.log(linkParties.value[1])
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };
    const handleFileUpload = (event) => {
      formData.value.file = event.target.files[0];
    };

    const uploadFile = async () => {
      const token = localStorage.getItem('access_token');
      const config = {
        headers: {
          'accept': 'application/json',
          Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data'
        }
      };

      const uploadData = new FormData();
      uploadData.append('applicantName', formData.value.applicantName);
      uploadData.append('accountName', formData.value.accountName);
      uploadData.append('movement', formData.value.movement);
      uploadData.append('location', formData.value.location);
      uploadData.append('vesselName', formData.value.vesselName);
      uploadData.append('voyageName', formData.value.voyageName);
      uploadData.append('file', formData.value.file);

      try {
        const response = await axios.post('/upload_file/', uploadData, config);
        console.log('File uploaded successfully:', response.data);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    };
    const handleChange = (event) => {
      const selectedShortName = event.target.value;
      updateApplicantAccountIds(selectedShortName);
    };
    const updateApplicantAccountIds = (value) => {
      const selectedParty = linkParties.value.find(party => party.autoid == value);
      if (selectedParty) {
        formData.value.applicantName = selectedParty.applicantId;
        formData.value.accountName = selectedParty.accountId;
      } else {
        formData.value.applicantName = 0;
        formData.value.accountName = 0;
      }
    };
    return {
      linkParties,
      movements,
      locations,
      vessels,
      voyages,
      formData,
      handleFileUpload,
      uploadFile,
      selectedShortName: '',
      handleChange,
      updateApplicantAccountIds,
    };
  },
};
</script>

<style scoped>
.upload-content-tile h1 {
  color: #343a40;
  font-size: 28px;
  margin-bottom: 25px;
  margin-left: 25px;
}

.form-row {
  margin-bottom: 20px;
  display: flex;
  margin-left: 25%;
  align-items: center;
}

.form-row label {
  font-weight: bold;
  margin-right: 10px;
  width: 200px;
  /* Adjust width as needed */
  color: #495057;
}

.custom-select {
  width: 100%;
  max-width: 300px;
  /* Reduce the width of the select box */
  padding: 8px;
  /* Adjust padding as needed */
  border: 2px solid #ced4da;
  border-radius: 8px;
  font-size: 16px;
  color: #495057;
  background-color: #fff;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%23495057" d="M2 0L0 2h4L2 0zM0 3l2 2 2-2H0z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px 12px;
}

.custom-file-input {
  width: 100%;
  max-width: 300px;
  /* Adjust the width to match the select box */
  padding: 8px;
  border: 2px solid #ced4da;
  border-radius: 8px;
  font-size: 16px;
  color: #495057;
  background-color: #fff;
}

.form-actions {
  margin-top: 30px;
  margin-left: 46%;
}

.form-actions button {
  padding: 12px 25px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.form-actions button:hover {
  background-color: #0056b3;
}
</style>